/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import React, { useCallback, useEffect, useState } from "react";

import { FontAwesome } from "@expo/vector-icons";
import { uuidv4 } from "@firebase/util";
import { manipulateAsync } from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Checkbox, Modal, Portal, Provider } from "react-native-paper";
import S3FileUpload from "react-s3";

import MultiSelectInput from "../components/MultiSelectInput";
import TextInput from "../components/TextInput";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Contents from "../constants/Contents";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import useColorScheme from "../hooks/useColorScheme";
import { Podcast, RootTabScreenProps } from "../types";
import { _searchList, getDefaultThumbnail } from "../utils/helper";

const S3_BUCKET = "monkitoxs3";
const REGION = "us-east-2";
const ACCESS_KEY = "AKIAZ2BTAGOCCLAMSDMD";
const SECRET_ACCESS_KEY = "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";
const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

//const analytics = getAnalytics(app);

export default function Admin({ navigation }: RootTabScreenProps<"Discover">) {
  const theme = useColorScheme();

  const [isLoading, setIsLoading] = useState(true);
  const [isAudioUploading, setIsAudioUploading] = useState(false);
  const [userPhone, setUserPhone] = useState<string | undefined | null>();

  const [seriesSelectionModalVisible, setSeriesSelectionModalVisible] =
    useState(false);
  const [statisticsModal, setStatisticsModal] = useState(false);
  const [podcastList, setPodcastList] = useState<Podcast[]>([]);
  const [filteredPodcastList, setFilteredPodcastList] = useState<Podcast[]>();
  const [podcast, setPodcast] = useState<Podcast>();
  const [searchText, setSearchText] = useState<string>();

  const db = getFirestore();

  useEffect(() => {
    if (getAuth().currentUser) {
      setUserPhone(getAuth().currentUser?.phoneNumber);
    }
  }, []);

  useEffect(() => {
    if (searchText && searchText !== "") {
      setFilteredPodcastList(_searchList(podcastList, searchText));
    } else {
      setFilteredPodcastList(undefined);
    }
  }, [podcastList, searchText]);

  async function uploadImageAsync(uri: string) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662

    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 250 } }],
      { compress: 1 }
    );
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", manipResult.uri, true);
      xhr.send(null);
    });

    const fileRef = ref(getStorage(), uuidv4());
    await uploadBytes(fileRef, blob);

    // We're done with the blob, close and release it
    //blob.close();

    return await getDownloadURL(fileRef);
  }

  const handleUpload = useCallback(async (file: any) => {
    setIsAudioUploading(true);
    S3FileUpload.uploadFile(file, config)
      .then((data: any) => {
        console.log(data);
        setPodcast((currentValue) => {
          return { ...currentValue, FileUrl: encodeURI(data.location) };
        });
        setIsAudioUploading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsAudioUploading(false);
        alert("Something went wrong while uploading audio file");
      });
  }, []);

  const pickImage = useCallback(async () => {
    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    console.log(result);

    if (!result.cancelled) {
      const image_url = await uploadImageAsync(result.uri);
      console.log(image_url);
      setPodcast({ ...podcast, Thumbnail: image_url });
    }
  }, [podcast]);

  const saveChanges = useCallback(async () => {
    if (!podcast) {
      return;
    }
    if (
      !podcast.Title ||
      !podcast.Genere ||
      !podcast.FileUrl ||
      !podcast.Language
    ) {
      alert("Title, Genere, File Url and Language are mandatory");
      return;
    }
    if (podcast?.isSeries && !podcast.NumberOfEpisodes) {
      alert("Number of Episodes is mandatory for series");
      return;
    }
    if (podcast?.Series && !podcast.Episode) {
      alert("Episode number is required when the podcast is part of a series");
      return;
    }
    const TagsList = podcast.Tags.map((s) => s.trim());
    if (podcast.isSeries) {
      podcast.Series = null; // Series cannot be part of another series
    }
    if (podcast.key) {
      const podcastRef = doc(db, "podcasts", podcast.key);

      await updateDoc(podcastRef, {
        ...podcast,
        lastUpdatedBy: getAuth().currentUser?.uid,
        Tags: TagsList,
      });

      const updatedList = podcastList;
      const index = podcastList.findIndex((x) => x.key === podcast.key);
      updatedList[index] = { ...podcast, Tags: TagsList };
      setPodcastList(updatedList);

      const updatedFilteredList = filteredPodcastList;
      const filteredIndex = filteredPodcastList?.findIndex(
        (x) => x.key === podcast.key
      );
      if (
        filteredIndex !== undefined &&
        filteredIndex >= 0 &&
        updatedFilteredList
      ) {
        updatedFilteredList[filteredIndex] = { ...podcast, Tags: TagsList };
        setFilteredPodcastList(updatedFilteredList);
      }

      alert("Updated Successfully");
    } else {
      const podcastRef = await addDoc(collection(db, "podcasts"), {
        ...podcast,
        Tags: TagsList,
        lastUpdatedBy: getAuth().currentUser?.uid,
        createdAt: serverTimestamp(),
      });
      const updatedList = podcastList;
      updatedList.push({ ...podcast, Tags: TagsList, key: podcastRef.id });
      setPodcastList(updatedList);
      setPodcast({ ...podcast, Tags: TagsList, key: podcastRef.id });

      const updatedFilteredList = filteredPodcastList;
      updatedFilteredList?.push({
        ...podcast,
        Tags: TagsList,
        key: podcastRef.id,
      });
      setFilteredPodcastList(updatedFilteredList);
      alert("Created Successfully");
    }
  }, [db, filteredPodcastList, podcast, podcastList]);

  const listItem = useCallback(
    ({
      item,
      onPress,
    }: {
      item: Podcast;
      index: number;
      onPress?: (item: Podcast) => void;
    }) => {
      return (
        <TouchableOpacity
          style={{
            ...Layout.listItemSize,
            marginLeft: 5,
            flexDirection: "row",
            marginTop: 5,
            maxWidth: Layout.window.height / 2,
          }}
          onPress={() => {
            if (onPress) {
              return onPress?.(item);
            }

            setPodcast({
              ...item,
              fromAgeInMonths: item.fromAgeInMonths || 0,
              toAgeInMonths: item.toAgeInMonths || 9999,
            });
          }}
        >
          <Image
            source={{ uri: item.Thumbnail }}
            style={{ ...Layout.thumbnailSmall, borderRadius: 5 }}
          />

          <View style={{ padding: 5 }}>
            <Text style={[Styles.textTileTitle]}>{item.Title}</Text>
            <View style={Styles.row}>
              {!item.isSeries && item.Episode !== undefined && (
                <View style={Styles.rowCenter}>
                  <Text
                    style={Styles.textSubTitle}
                  >{`Episode ${item.Episode}`}</Text>
                  <View
                    style={{
                      marginHorizontal: 5,
                      width: 5,
                      height: 5,
                      borderRadius: 5,
                      backgroundColor: Colors.grey,
                    }}
                  />
                </View>
              )}
              <Text style={Styles.textSubTitle}>{item.Language}</Text>
              {item.isSeries && item.NumberOfEpisodes && (
                <View style={Styles.rowCenter}>
                  <View
                    style={{
                      marginHorizontal: 5,
                      width: 5,
                      height: 5,
                      borderRadius: 5,
                      backgroundColor: Colors.grey,
                    }}
                  />
                  <Text
                    style={Styles.textSubTitle}
                  >{`${item.NumberOfEpisodes} episodes`}</Text>
                </View>
              )}
            </View>
          </View>
        </TouchableOpacity>
      );
    },
    []
  );

  const renderList = useCallback(() => {
    return (
      <FlatList
        data={filteredPodcastList || podcastList}
        style={{
          marginTop: 10,
          paddingLeft: 50,
          height: Layout.window.height * 0.75,
          //marginBottom: 100 + ((playingNow.isPlayingNow || playingNow.Title || playingNow.live) ? 50 : 0)
        }}
        ListHeaderComponent={
          <View>
            <View style={Styles.flexRowCenterSpaceAround}>
              <TouchableOpacity onPress={() => setStatisticsModal(true)}>
                <Text style={{ fontSize: 20, color: Colors.primary }}>
                  {podcastList.filter((podcast) => !podcast.isSeries).length}{" "}
                  Podcasts
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  backgroundColor: Colors.primary,
                  borderRadius: 5,
                  width: 200,
                  alignSelf: "center",
                  marginVertical: 20,
                }}
                onPress={() =>
                  setPodcast({
                    Title: "",
                    Tags: [],
                    Genere: [],
                    Description: "",
                    Thumbnail: "",
                    Language: "English",
                    FileUrl: "",
                    isSeries: false,
                    Series: null,
                    ExcludeFromLive: false,
                    isPremium: true,
                    isFeatured: false,
                    isNewRelease: true,
                    fromAgeInMonths: 0,
                    toAgeInMonths: 9999,
                  } as Podcast)
                }
              >
                <Text style={{ fontSize: 20, textAlign: "center" }}>
                  Add New Podcast
                </Text>
              </TouchableOpacity>
            </View>
            <TextInput
              value={searchText}
              label={"Search"}
              onChangeText={setSearchText}
            />
          </View>
        }
        keyExtractor={(item) => item.key || ""}
        renderItem={listItem}
      />
    );
  }, [filteredPodcastList, listItem, podcastList, searchText]);
  const handleFileSelect = useCallback(
    (e) => {
      setIsAudioUploading(true);
      handleUpload(e.target.files[0]);
    },
    [handleUpload]
  );

  const renderDetails = useCallback(() => {
    return (
      <View
        style={{
          width: Layout.window.width / 2,
          borderWidth: 2,
          borderColor: Colors.primary,
          marginLeft: 10,
        }}
      >
        {!podcast && (
          <View style={styles.container}>
            <Text style={{ fontSize: 30 }}>
              Select a Podcast to view the details
            </Text>
          </View>
        )}
        {podcast && (
          <View>
            {podcast.Thumbnail ? (
              <TouchableOpacity
                style={{
                  marginLeft: 20,
                  marginTop: 30,
                  marginBottom: 20,
                  alignItems: "center",
                }}
                onPress={pickImage}
              >
                <Image
                  source={{ uri: podcast.Thumbnail }}
                  style={{ width: 100, height: 100 }}
                />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={{
                  marginLeft: 20,
                  marginTop: 30,
                  marginBottom: 20,
                  alignItems: "center",
                }}
                onPress={pickImage}
              >
                <FontAwesome name="upload" color={Colors.primary} size={100} />
                <Text>Upload Thumbnail</Text>
              </TouchableOpacity>
            )}
            <TextInput
              value={podcast.Title}
              label={"Title"}
              onChangeText={(value: string) => {
                setPodcast({ ...podcast, Title: value });
              }}
            />
            <TextInput
              value={podcast.Description}
              label={"Description"}
              onChangeText={(value: string) => {
                setPodcast({ ...podcast, Description: value });
              }}
              multiline
            />
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 9 }}>
                <TextInput
                  value={podcast.FileUrl}
                  label={"File Url"}
                  onChangeText={(value: string) => {
                    setPodcast({ ...podcast, FileUrl: encodeURI(value) });
                  }}
                  multiline={true}
                />
              </View>
              <View
                style={{
                  flex: 2,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isAudioUploading ? (
                  <ActivityIndicator
                    isLoading
                    size="large"
                    color={Colors[theme].primary}
                  />
                ) : (
                  <input type="file" onChange={handleFileSelect} />
                )}
              </View>
            </View>

            <MultiSelectInput
              label="Language"
              options={Contents.languages}
              values={[podcast.Language]}
              singleSelect
              onUpdate={(values: string[]) => {
                setPodcast({ ...podcast, Language: values[0] });
              }}
            />
            <MultiSelectInput
              label="Genere"
              options={[
                "Stories",
                "Mythology",
                "Do you Know",
                "Sleep Music",
                "Language Learning",
              ]}
              values={podcast.Genere}
              onUpdate={(values: string[]) => {
                setPodcast({ ...podcast, Genere: values });
              }}
            />

            <View style={Styles.flexRowCenterSpaceBetween}>
              <TextInput
                value={podcast.fromAgeInMonths}
                label={"From Months"}
                onChangeText={(value: string) => {
                  setPodcast({
                    ...podcast,
                    fromAgeInMonths: value ? parseInt(value) : 0,
                  });
                }}
              />
              <TextInput
                value={podcast.toAgeInMonths}
                label={"To Months"}
                onChangeText={(value: string) => {
                  setPodcast({
                    ...podcast,
                    toAgeInMonths: value ? parseInt(value) : 0,
                  });
                }}
              />
            </View>

            <TextInput
              value={podcast?.Tags?.toString()}
              label={"Tags"}
              onChangeText={(value: string) => {
                setPodcast({ ...podcast, Tags: value.split(",") });
              }}
            />
            <View style={[Styles.rowCenter, { marginLeft: 20, marginTop: 10 }]}>
              <Text style={{ color: Colors.primary, fontSize: 16 }}>
                Is Premium Content
              </Text>
              <Checkbox
                uncheckedColor={Colors.grey}
                color={Colors.primary}
                status={podcast.isPremium ? "checked" : "unchecked"}
                onPress={() => {
                  setPodcast({ ...podcast, isPremium: !podcast.isPremium });
                }}
              />
            </View>
            <View style={[Styles.rowCenter, { marginLeft: 20, marginTop: 10 }]}>
              <Text style={{ color: Colors.primary, fontSize: 16 }}>
                Exclude from Live
              </Text>
              <Checkbox
                uncheckedColor={Colors.grey}
                color={Colors.primary}
                status={podcast.ExcludeFromLive ? "checked" : "unchecked"}
                onPress={() => {
                  setPodcast({
                    ...podcast,
                    ExcludeFromLive: !podcast.ExcludeFromLive,
                  });
                }}
              />
            </View>

            <View style={[Styles.rowCenter, { marginLeft: 20, marginTop: 10 }]}>
              <Text style={{ color: Colors.primary, fontSize: 16 }}>
                Show as Featured
              </Text>
              <Checkbox
                uncheckedColor={Colors.grey}
                color={Colors.primary}
                status={podcast.isFeatured ? "checked" : "unchecked"}
                onPress={() => {
                  setPodcast({
                    ...podcast,
                    isFeatured: !podcast.isFeatured,
                  });
                }}
              />
            </View>

            <View style={[Styles.rowCenter, { marginLeft: 20, marginTop: 10 }]}>
              <Text style={{ color: Colors.primary, fontSize: 16 }}>
                Show as New Release
              </Text>
              <Checkbox
                uncheckedColor={Colors.grey}
                color={Colors.primary}
                status={podcast.isNewRelease ? "checked" : "unchecked"}
                onPress={() => {
                  setPodcast({
                    ...podcast,
                    isNewRelease: !podcast.isNewRelease,
                  });
                }}
              />
            </View>
            <View style={[Styles.rowCenter, { marginLeft: 20, marginTop: 10 }]}>
              <Text style={{ color: Colors.primary, fontSize: 16 }}>
                This is a series header
              </Text>
              <Checkbox
                uncheckedColor={Colors.grey}
                color={Colors.primary}
                status={podcast.isSeries ? "checked" : "unchecked"}
                onPress={() => {
                  setPodcast({ ...podcast, isSeries: !podcast.isSeries });
                }}
              />
            </View>

            {!podcast.isSeries && (
              <TouchableOpacity
                style={[Styles.rowCenter, { marginLeft: 20, marginTop: 10 }]}
                onPress={() => setSeriesSelectionModalVisible(true)}
              >
                {podcast.Series ? (
                  <View>
                    <Text style={{ color: Colors.primary, fontSize: 16 }}>
                      Series
                    </Text>
                    <Text style={{ color: Colors.grey, fontSize: 14 }}>
                      {
                        podcastList.find(
                          (listItem) => listItem.key === podcast.Series
                        )?.Title
                      }
                    </Text>
                  </View>
                ) : (
                  <Text style={{ color: Colors.primary, fontSize: 16 }}>
                    Link to Series
                  </Text>
                )}
              </TouchableOpacity>
            )}
            {podcast.Series && (
              <TextInput
                value={podcast.Episode?.toString()}
                label={"Episode Number"}
                onChangeText={(value: string) => {
                  setPodcast({ ...podcast, Episode: parseInt(value) });
                }}
              />
            )}

            {podcast.isSeries && (
              <TextInput
                value={podcast.NumberOfEpisodes?.toString()}
                label={"Number of Episodes"}
                onChangeText={(value: string) => {
                  setPodcast({ ...podcast, NumberOfEpisodes: parseInt(value) });
                }}
              />
            )}

            <TouchableOpacity
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: Colors.primary,
                borderRadius: 5,
                width: 150,
                alignSelf: "center",
                marginVertical: 20,
              }}
              onPress={saveChanges}
            >
              <Text style={{ fontSize: 20 }}>Save Changes</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }, [
    podcast,
    pickImage,
    isAudioUploading,
    theme,
    handleFileSelect,
    podcastList,
    saveChanges,
  ]);
  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);
      const q = query(collection(db, "podcasts"), orderBy("Title"));
      const podcasts: Podcast[] = [];

      const querySnapshot = await getDocs(q);
      for (const [index, doc] of querySnapshot.docs.entries()) {
        const _podcast = doc.data() as Podcast;
        _podcast.key = doc.id;
        if (!_podcast.Thumbnail) {
          _podcast.Thumbnail = getDefaultThumbnail(index);
        }
        podcasts.push(_podcast);
      }
      setPodcastList(podcasts);
      setIsLoading(false);
    }

    fetchMyAPI();
  }, [db]);

  return (
    <Provider>
      <Portal>
        <Modal
          visible={seriesSelectionModalVisible}
          onDismiss={() => setSeriesSelectionModalVisible(false)}
          contentContainerStyle={styles.modalContainer}
        >
          <View style={{ flex: 1, padding: 20 }}>
            <Text style={{ fontSize: 16, marginBottom: 20 }}>
              {"Select the series to link to"}
            </Text>
            <FlatList
              data={podcastList.filter((_podcast) => _podcast.isSeries)}
              style={{
                marginTop: 10,
                paddingLeft: 50,
                height: Layout.window.height * 0.75,
                //marginBottom: 100 + ((playingNow.isPlayingNow || playingNow.Title || playingNow.live) ? 50 : 0)
              }}
              keyExtractor={(item) => item.key || ""}
              renderItem={({ item, index }) =>
                listItem({
                  item,
                  index,
                  onPress: (item) => {
                    if (item.key) {
                      setPodcast({ ...podcast, Series: item.key || null });
                    }

                    setSeriesSelectionModalVisible(false);
                  },
                })
              }
            />
          </View>
        </Modal>

        <Modal
          visible={statisticsModal}
          onDismiss={() => setStatisticsModal(false)}
          contentContainerStyle={styles.modalContainer}
        >
          <View style={{ flex: 1, padding: 20 }}>
            <Text
              style={{ fontSize: 16, marginBottom: 20, color: Colors.coral }}
            >
              {"Podcast Statistics"}
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>Language</Text>
              <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                Free/Total
              </Text>
            </View>

            {Contents.languages.map((language) => {
              const languagePodcasts = podcastList.filter(
                (podcast) => podcast.Language === language && !podcast.isSeries
              );
              return (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                  }}
                >
                  <Text style={{ fontSize: 16 }}>{language}</Text>
                  <Text style={{ fontSize: 16 }}>
                    {languagePodcasts.filter(
                      (podcast) => !podcast.isPremium && !podcast.isSeries
                    ).length +
                      "/ " +
                      languagePodcasts.length}
                  </Text>
                </View>
              );
            })}
          </View>
        </Modal>
      </Portal>
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0 },
        ]}
      >
        <View style={{ alignItems: "center", paddingHorizontal: 50 }}>
          {isLoading ? (
            <View style={{}}>
              <ActivityIndicator
                isLoading
                size="small"
                color={Colors[theme].primary}
              />
            </View>
          ) : podcastList?.length ? (
            <View style={{ flexDirection: "row" }}>
              {renderList()}
              {renderDetails()}
            </View>
          ) : (
            <Text>No Podcasts Found</Text>
          )}
        </View>
      </ScrollView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    pading: 25,
    width: Layout.window.width * 0.5,
    height: Layout.window.height * 0.7,
    alignSelf: "center",
    borderWidth: 5,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
});
