/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */

/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import React, { useCallback, useEffect, useState } from "react";

import { FontAwesome } from "@expo/vector-icons";
import { uuidv4 } from "@firebase/util";
import { manipulateAsync } from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Checkbox, Provider } from "react-native-paper";
import S3FileUpload from "react-s3";

import MultiSelectInput from "../components/MultiSelectInput";
import TextInput from "../components/TextInput";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import useColorScheme from "../hooks/useColorScheme";
import { Activity, HomeScreenBanner, RootTabScreenProps } from "../types";
import { _searchList } from "../utils/helper";
import Contents from "../constants/Contents";

//const analytics = getAnalytics(app);

export default function AdminHomescreenBanner({
  navigation,
}: RootTabScreenProps<"Discover">) {
  const theme = useColorScheme();

  const [isLoading, setIsLoading] = useState(true);

  const [bannerList, setbannerList] = useState<HomeScreenBanner[]>([]);
  const [filteredBannerList, setFilteredbannerList] =
    useState<HomeScreenBanner[]>();
  const [banner, setBanner] = useState<HomeScreenBanner>();
  const [index, setIndex] = useState<number>();
  const [searchText, setSearchText] = useState<string>();
  const [isVideoUploading, setIsVideoUploading] = useState(false);

  const db = getFirestore();

  useEffect(() => {
    if (searchText && searchText !== "") {
      setFilteredbannerList(_searchList(bannerList, searchText));
    } else {
      setFilteredbannerList(undefined);
    }
  }, [bannerList, searchText]);

  async function uploadImageAsync(uri: string) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662

    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 540 } }],
      { compress: 1 }
    );
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", manipResult.uri, true);
      xhr.send(null);
    });

    const fileRef = ref(getStorage(), uuidv4());
    await uploadBytes(fileRef, blob);

    // We're done with the blob, close and release it
    //blob.close();

    return await getDownloadURL(fileRef);
  }

  const pickImage = useCallback(
    async (index?: number) => {
      // No permissions request is necessary for launching the image library
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
      });

      console.log(result);

      if (!result.canceled) {
        const image_url = await uploadImageAsync(result.uri);
        console.log(image_url);
        setBanner({
          ...banner,
          bannerImage: image_url,
        } as HomeScreenBanner);
      }
    },
    [banner]
  );

  const saveChanges = useCallback(async () => {
    if (!banner) {
      return;
    }
    if (!banner.bannerImage || !banner.bannerTitle || !banner.bannerType) {
      alert("Enter all mandatory fields");
      return;
    }

    const _bannerList = bannerList;
    if (index !== undefined) {
      _bannerList[index] = banner;
    } else {
      _bannerList.unshift(banner);
    }
    const bannerListDoc = doc(db, "config", "homeScreenBanner");

    updateDoc(bannerListDoc, {
      list: _bannerList,
    }).then(() => {
      alert("Updated Successfully");
      setBanner(undefined);
      setIndex(undefined);
      setbannerList(_bannerList);
    });
  }, [db, index, banner, bannerList]);

  const listItem = useCallback(
    ({
      item,
      index,
      onPress,
    }: {
      item: HomeScreenBanner;
      index: number;
      onPress?: (item: HomeScreenBanner) => void;
    }) => {
      return (
        <TouchableOpacity
          style={{
            ...Layout.listItemSize,
            marginLeft: 5,
            flexDirection: "row",
            marginTop: 5,
            maxWidth: Layout.window.height / 2,
          }}
          onPress={() => {
            if (onPress) {
              return onPress?.(item);
            }

            setBanner(item);
            setIndex(index);
          }}
        >
          <Image
            source={{ uri: item.bannerImage }}
            style={{ ...Layout.thumbnailSmall, borderRadius: 5, width: 90 }}
          />

          <View style={{ padding: 5 }}>
            <Text style={[Styles.textTileTitle]}>{item.bannerTitle}</Text>
            <View style={Styles.row}></View>
          </View>
        </TouchableOpacity>
      );
    },
    []
  );

  const moveUp = useCallback(
    (index: number) => {
      let _bannerList = bannerList;

      if (index > 0) {
        let el = _bannerList[index];
        _bannerList[index] = _bannerList[index - 1];
        _bannerList[index - 1] = el;
        setbannerList([..._bannerList]);
        setIndex(index - 1);
      }
    },
    [bannerList]
  );

  const moveDown = useCallback(
    (index: number) => {
      let _bannerList = bannerList;
      if (index !== -1 && index < _bannerList.length - 1) {
        let el = _bannerList[index];
        _bannerList[index] = _bannerList[index + 1];
        _bannerList[index + 1] = el;
        setbannerList([..._bannerList]);
        setIndex(index + 1);
      }
    },
    [bannerList]
  );

  const renderList = useCallback(() => {
    return (
      <FlatList
        data={filteredBannerList || bannerList}
        style={{
          marginTop: 10,
          paddingLeft: 50,
          height: Layout.window.height * 0.75,
          //marginBottom: 100 + ((playingNow.isPlayingNow || playingNow.Title || playingNow.live) ? 50 : 0)
        }}
        ListHeaderComponent={
          <View>
            <View style={Styles.flexRowCenterSpaceAround}>
              <Text style={{ fontSize: 30 }}>{bannerList.length} Banners</Text>
              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  backgroundColor: Colors.primary,
                  borderRadius: 5,
                  width: 200,
                  alignSelf: "center",
                  marginVertical: 20,
                }}
                onPress={() => {
                  setBanner({
                    bannerTitle: "",
                    bannerStatus: true,
                    bannerType: "Info",
                    bannerTopic: ["All"],
                    bannerTarget: "",
                    bannerAction: "None",
                  } as HomeScreenBanner);
                  setIndex(undefined);
                }}
              >
                <Text style={{ fontSize: 20, textAlign: "center" }}>
                  Add New Banner
                </Text>
              </TouchableOpacity>
            </View>
            <TextInput
              value={searchText}
              label={"Search"}
              onChangeText={setSearchText}
            />
          </View>
        }
        keyExtractor={(item) => item.key || ""}
        renderItem={({ item, index }) => listItem({ item, index })}
      />
    );
  }, [filteredBannerList, listItem, bannerList, searchText]);

  const renderDetails = useCallback(() => {
    return (
      <View
        style={{
          width: Layout.window.width / 2,
          borderWidth: 2,
          borderColor: Colors.primary,
          marginLeft: 10,
        }}
      >
        {!banner && (
          <View style={styles.container}>
            <Text style={{ fontSize: 30, color: Colors.white }}>
              Select a banner to view the details
            </Text>
          </View>
        )}
        {banner && (
          <View>
            <TouchableOpacity
              style={{
                marginLeft: 20,
                marginTop: 30,
                marginBottom: 20,
                alignItems: "center",
              }}
              onPress={() => pickImage()}
            >
              {banner.bannerImage ? (
                <Image
                  source={{ uri: banner.bannerImage }}
                  style={{ width: 540, height: 270, borderRadius: 15 }}
                />
              ) : (
                <FontAwesome name="image" size={100} color={Colors.primary} />
              )}
            </TouchableOpacity>
            <Text style={{ textAlign: "center" }}>
              {"Image Size - Width:540px & Height:270px "}
            </Text>
            <TextInput
              value={banner.bannerTitle}
              label={"Title"}
              onChangeText={(value: string) => {
                setBanner({ ...banner, bannerTitle: value });
              }}
            />
            <TextInput
              value={banner.bannerDescr}
              label={"Description"}
              onChangeText={(value: string) => {
                setBanner({ ...banner, bannerDescr: value });
              }}
            />
            <MultiSelectInput
              label="Type"
              options={["Info", "Activity", "Food", "Podcast", "Task"]}
              values={[banner.bannerType]}
              singleSelect
              onUpdate={(values: string[]) => {
                setBanner({ ...banner, bannerType: values[0] });
              }}
            />

            <MultiSelectInput
              label="Target Audience"
              options={["All", ...Contents.languages]}
              values={banner.bannerTopic || ["All"]}
              onUpdate={(values: string[]) => {
                setBanner({ ...banner, bannerTopic: values });
              }}
            />

            <MultiSelectInput
              label="Action"
              options={["Navigate", "Link", "None"]}
              values={[banner.bannerAction]}
              singleSelect
              onUpdate={(values: string[]) => {
                setBanner({ ...banner, bannerAction: values[0] });
              }}
            />
            <TextInput
              value={banner.bannerTarget || ""}
              label={"Action Target"}
              onChangeText={(value: string) => {
                setBanner({ ...banner, bannerTarget: value });
              }}
            />

            <View style={[Styles.rowCenter, { marginLeft: 20, marginTop: 10 }]}>
              <Text style={{ color: Colors.primary, fontSize: 16 }}>
                Is Active
              </Text>
              <Checkbox
                uncheckedColor={Colors.grey}
                color={Colors.primary}
                status={banner.bannerStatus ? "checked" : "unchecked"}
                onPress={() => {
                  setBanner({
                    ...banner,
                    bannerStatus: !banner.bannerStatus,
                  });
                }}
              />
            </View>

            {index != undefined && (
              <View
                style={[Styles.flexRowCenterSpaceAround, { width: "100%" }]}
              >
                <TouchableOpacity
                  onPress={() => moveUp(index)}
                  style={Styles.flexRowCenter}
                >
                  <Text
                    style={{
                      color: Colors.primary,
                      textAlign: "center",
                      fontSize: 16,
                    }}
                  >
                    Move Up
                  </Text>
                  <FontAwesome
                    name="arrow-up"
                    size={16}
                    color={Colors.primary}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => moveDown(index)}
                  style={Styles.flexRowCenter}
                >
                  <Text
                    style={{
                      color: Colors.primary,
                      textAlign: "center",
                      fontSize: 16,
                    }}
                  >
                    Move Down
                  </Text>
                  <FontAwesome
                    name="arrow-down"
                    size={16}
                    color={Colors.primary}
                  />
                </TouchableOpacity>
              </View>
            )}
            <TouchableOpacity
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: Colors.primary,
                borderRadius: 5,
                width: 150,
                alignSelf: "center",
                marginVertical: 20,
              }}
              onPress={saveChanges}
            >
              <Text style={{ fontSize: 20 }}>Save Changes</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }, [
    bannerList,
    isVideoUploading,
    pickImage,
    saveChanges,
    theme,
    banner,
    index,
  ]);

  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);

      const activities: Activity[] = [];

      getDoc(doc(db, "config", "homeScreenBanner")).then(
        (homeScreenBannerDoc) => {
          if (homeScreenBannerDoc.exists()) {
            const list = homeScreenBannerDoc.data().list as HomeScreenBanner[];
            setbannerList(list);
            setIsLoading(false);
          }
        }
      );
    }

    fetchMyAPI();
  }, [db]);

  return (
    <Provider>
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0 },
        ]}
      >
        <View style={{ alignItems: "center", paddingHorizontal: 50 }}>
          {isLoading ? (
            <View style={{}}>
              <ActivityIndicator
                isLoading
                size="small"
                color={Colors[theme].primary}
              />
            </View>
          ) : (
            <View style={{ flexDirection: "row" }}>
              {renderList()}
              {renderDetails()}
            </View>
          )}
        </View>
      </ScrollView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
