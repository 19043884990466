/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import { Card, Checkbox } from "@chakra-ui/react";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import {
  FlatList,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { ChakraInput, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import { DATE_FORMAT } from "../constants/Config";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import { CRM, RootTabScreenProps, User } from "../types";
import UserDetails from "./UserDetails";
import { Dropdown } from "react-native-element-dropdown";
//const analytics = getAnalytics(app);

export default function UserList({
  navigation,
}: RootTabScreenProps<"Discover">) {
  const db = getFirestore();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState<User[]>([]);
  const [filteredUserList, setFilteredUserList] = useState<User[]>();
  const [searchText, setSearchText] = useState("");
  const [filterFollowUp, setFilterFollowUp] = useState<"All" | "Yes" | "No">(
    "All"
  );
  const [filterWelcomeCall, setFilterWelcomeCall] = useState<
    "All" | "Yes" | "No"
  >("All");

  const [filterComments, setFilterComments] = useState<"All" | "Yes" | "No">(
    "All"
  );

  const [sortBy, setSortBy] = useState<
    | "Creation Date - Desc"
    | "Creation Date - Asc"
    | "Last used - Desc"
    | "Last used - Asc"
  >("Creation Date - Desc");

  const [filterKey, setFilterKey] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const windowHeight = useWindowDimensions().height;
  const listHeight = windowHeight * 0.85;

  const fetchMyAPI = useCallback(async () => {
    setIsLoading(true);
    const functions = getFunctions();
    if (__DEV__) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
    const crmInfo: CRM[] = [];

    const getUsers = httpsCallable(functions, "getUsers");
    getUsers().then(async (result) => {
      await getDocs(collection(db, "CRM")).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          crmInfo.push({ ...doc.data(), uid: doc.id } as CRM);
        });
      });
      console.warn(result.data);
      const _userList: User[] = result.data.map((user: User) => {
        const crm = crmInfo.find((crm) => crm.uid === user.uid);
        return { ...user, crm };
      });
      setUserList(_userList);
    });

    setIsLoading(false);
  }, [db]);

  // useEffect(() => {
  //   fetchMyAPI();
  // }, [db]);

  useEffect(() => {
    fetchMyAPI();
  }, []);

  useEffect(() => {
    const _filtetedUserList = userList.filter((user) => {
      let isMatch = true;
      if (filterFollowUp === "Yes" && !user.crm?.followUpRequired) {
        isMatch = false;
      }
      if (filterFollowUp === "No" && user.crm?.followUpRequired) {
        isMatch = false;
      }
      if (filterWelcomeCall === "Yes" && !user.crm?.welcomeCall) {
        isMatch = false;
      }
      if (filterWelcomeCall === "No" && user.crm?.welcomeCall) {
        isMatch = false;
      }
      if (filterComments === "Yes" && !user.crm?.comments.length) {
        isMatch = false;
      }
      if (filterComments === "No" && user.crm?.comments.length) {
        isMatch = false;
      }
      if (
        searchText !== "" &&
        !user.phone.toLowerCase().includes(searchText.toLowerCase())
      ) {
        isMatch = false;
      }
      return isMatch;
    });

    // if (searchText === "") {
    //   setFilteredUserList(undefined);
    // } else {
    //   setFilteredUserList(
    //     userList.filter((user) =>
    //       user.phone.toLowerCase().includes(searchText.toLowerCase())
    //     )
    //   );

    if (sortBy === "Creation Date - Desc") {
      setFilteredUserList(_filtetedUserList);
    } else if (sortBy === "Creation Date - Asc") {
      console.warn("Creation Date - Asc");
      setFilteredUserList(
        _filtetedUserList.toSorted((a, b) =>
          moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1
        )
      );
    } else if (sortBy === "Last used - Desc") {
      setFilteredUserList(
        _filtetedUserList.toSorted((a, b) =>
          moment(a.lastRefresh).isBefore(moment(b.lastRefresh)) ? 1 : -1
        )
      );
    } else if (sortBy === "Last used - Asc") {
      setFilteredUserList(
        _filtetedUserList.toSorted((a, b) =>
          moment(a.lastRefresh).isAfter(moment(b.lastRefresh)) ? 1 : -1
        )
      );
    }
  }, [
    userList,
    searchText,
    filterFollowUp,
    filterWelcomeCall,
    sortBy,
    filterComments,
  ]);

  return (
    <View>
      <UserDetails
        isVisble={isOpen}
        selectedUser={selectedUser}
        setIsVisible={setIsOpen}
      />
      <FlatList
        ListHeaderComponent={
          <View style={Styles.flexRowCenter}>
            <ChakraInput
              placeholder="Search"
              value={searchText}
              onChangeText={(text) => setSearchText(text)}
              style={Styles.margin}
            />
            <Dropdown
              style={[Styles.dropdown, { marginTop: 10, width: 225 }]}
              data={[
                { label: "Follow up Required - All", value: "All" },
                { label: "Follow up Required - Yes", value: "Yes" },
                { label: "Follow up Required - No", value: "No" },
              ]}
              maxHeight={300}
              labelField={"label"}
              valueField={"value"}
              placeholder="Select Vendor"
              value={filterFollowUp}
              onChange={(value) => {
                setFilterFollowUp(value.value as "All" | "Yes" | "No");
              }}
            />
            <Dropdown
              style={[
                Styles.dropdown,
                { marginTop: 10, marginLeft: 10, width: 200 },
              ]}
              data={[
                { label: "Welcome Call - All", value: "All" },
                { label: "Welcome Call - Yes", value: "Yes" },
                { label: "Welcome Call - No", value: "No" },
              ]}
              maxHeight={300}
              labelField={"label"}
              valueField={"value"}
              placeholder="Select Vendor"
              value={filterWelcomeCall}
              onChange={(value) => {
                setFilterWelcomeCall(value.value as "All" | "Yes" | "No");
              }}
            />
            <Dropdown
              style={[
                Styles.dropdown,
                { marginTop: 10, marginLeft: 10, width: 200 },
              ]}
              data={[
                { label: "Has Comments - All", value: "All" },
                { label: "Has Comments - Yes", value: "Yes" },
                { label: "Has Comments - No", value: "No" },
              ]}
              maxHeight={300}
              labelField={"label"}
              valueField={"value"}
              placeholder="Select Vendor"
              value={filterComments}
              onChange={(value) => {
                setFilterComments(value.value as "All" | "Yes" | "No");
              }}
            />

            <Dropdown
              style={[
                Styles.dropdown,
                { marginTop: 10, marginLeft: 10, width: 200 },
              ]}
              data={[
                {
                  label: "Creation Date - Desc",
                  value: "Creation Date - Desc",
                },
                { label: "Creation Date - Asc", value: "Creation Date - Asc" },
                { label: "Last used - Desc", value: "Last used - Desc" },
                { label: "Last used - Asc", value: "Last used - Asc" },
              ]}
              maxHeight={300}
              labelField={"label"}
              valueField={"value"}
              placeholder="Select Sort By"
              value={sortBy}
              onChange={(value) => {
                setSortBy(value.value as any);
              }}
            />
          </View>
        }
        style={{ height: listHeight }}
        showsVerticalScrollIndicator={true}
        data={filteredUserList || userList}
        keyExtractor={(item) => item.uid}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={[Styles.margin, { width: 500 }]}
            onPress={() => {
              setSelectedUser(item);
              setIsOpen(true);
            }}
          >
            <Card p={3}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text style={Styles.textTitle}>{item.phone}</Text>
                  <Text>{`Created On: ${moment(item.createdAt).format(
                    DATE_FORMAT
                  )}`}</Text>
                  <Text>{`Last Sign In: ${moment(item.lastSignIn).format(
                    DATE_FORMAT
                  )}`}</Text>
                  <Text>{`Last Used: ${moment(item.lastRefresh).format(
                    DATE_FORMAT
                  )}`}</Text>
                </View>
                <View>
                  <Checkbox
                    colorScheme="blue"
                    isChecked={item.crm?.welcomeCall}
                    isReadOnly={true}
                  >
                    Welcome Call
                  </Checkbox>

                  <Checkbox
                    colorScheme="blue"
                    isChecked={item.crm?.followUpRequired}
                    isReadOnly={true}
                  >
                    Follow Up Required
                  </Checkbox>
                </View>
              </View>
            </Card>
          </TouchableOpacity>
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    pading: 25,
    width: Layout.window.width * 0.5,
    height: Layout.window.height * 0.7,
    alignSelf: "center",
    borderWidth: 5,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: Colors.primary, // Adjust label color as needed
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  card: {
    borderRadius: 10,
    shadowColor: "#000",
    width: "100%",
    height: 150,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    padding: 20,
  },
});
