/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */

import { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import {
  CompositeScreenProps,
  NavigatorScreenParams,
} from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Timestamp } from "firebase/firestore";

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export type RootStackParamList = {
  Root: NavigatorScreenParams<RootTabParamList> | undefined;
  PodcastDetailsScreen: undefined;
  HomeScreen: undefined;
  ActivityScreen: undefined;
  ActivityDetailsScreen: undefined;
  PodcastListScreen: { Genere: { id: string; title: string; color: string } };
  PodcastSeriesScreen: { podcast: Podcast };
  LanguageScreen: undefined;
  TnC: undefined;
  Admin: undefined;
  MonkiTox: undefined;
  LoginScreen: undefined;
  ProfileScreen: undefined;
  SubscriptionScreen: undefined;
  MandatoryUpdateScreen: { isOptional: boolean };
  Activities: undefined;
  Food: undefined;
  FoodDetailsScreen: { food: Food };
  Onboarding: undefined;
  Product: undefined;
  AdminActivityList: undefined;
  AdminFoodList: undefined;
  AdminPodcastList: undefined;
  VendorList: undefined;
  ProductList: undefined;
  AdminNotificationList: undefined;
};

export type RootStackScreenProps<Screen extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, Screen>;

export type RootTabParamList = {
  Home: undefined;
  Activities: undefined;
  Profile: undefined;
  Discover: undefined;
  PodcastListScreen: { Genere: { id: string; title: string; color: string } };
  PodcastSeriesScreen: { podcast: Podcast };
  TnC: undefined;
  LanguageScreen: undefined;
  FoodScreen: undefined;
  FoodDetailsScreen: { food: Food };
};

export type RootTabScreenProps<Screen extends keyof RootTabParamList> =
  CompositeScreenProps<
    BottomTabScreenProps<RootTabParamList, Screen>,
    NativeStackScreenProps<RootStackParamList>
  >;
export type Store = {
  playlist: {
    playingNow: Podcast & {
      isPlayingNow: boolean;
      live: boolean;
      sleepMusic: boolean;
    };
    index: number;
    list: Podcast[];
    playbackObject: any;
    playbackStatus: any;
  };
  profile_data: {
    uid: string;
    currentProfile: Profile;
    subscription: any;
  };
  modal: { modalVisible: boolean };
};

export type Podcast = {
  key?: string;
  Author?: string;
  FileUrl: string;
  Genere: string[];
  Tags: string[];
  Thumbnail: string;
  Title: string;
  Language: string;
  Description?: string;
  isSeries: boolean;
  Series?: string | null;
  NumberOfEpisodes?: number;
  Episode?: number;
  ExcludeFromLive?: boolean;
  isFeatured?: boolean;
  isNewRelease?: boolean;
  isPremium?: boolean;
  fromAgeInMonths: number;
  toAgeInMonths: number;
};
export type Vendor = {
  key?: string;
  AddressLine1: string;
  City: string;
  Country: string;
  Email: string;
  InternationalShipping: boolean;
  Logo: string;
  Name: string;
  Phone: string;
  Instagram?: string;
  Postal: string;
  State: string;
  Status: boolean;
  Commission: number;
};

export type ProductVariant = {
  color?: string;
  size?: string;
  material?: string;
  packing?: string;
  other?: string;
  price: string;
  currency: string;
  inStock: boolean;
  sku: string;
  costPrice: string;
  gst: number;
  deliveryDays: string;
  images: string[];
  videos: string[];
  fromAgeInMonths: string;
  toAgeInMonths: string;
};

export type Product = {
  key?: string;
  name: string;
  description: string;
  specifications?: string;
  fromAgeInMonths: string;
  toAgeInMonths: string;
  price: string;
  currency: string;
  images: string[];
  videos: string[];
  downloadables?: string[];
  inStock: boolean;
  vendor: string;
  category: string;
  sku: string;
  costPrice: string;
  deliveryDays: string;
  customFields?: {
    name: string;
    description: string;
  }[];
  isPersonalized: boolean;
  isDownloadable?: boolean;
  gst: number;
  hasVariants: boolean;
  hasColorVariant?: boolean;
  hasSizeVariant?: boolean;
  hasMaterialVariant?: boolean;
  hasPackingVariant?: boolean;
  hasOtherVariant?: boolean;
  uniqueColorVariants?: string[];
  uniqueSizeVariants?: string[];
  uniqueMaterialVariants?: string[];
  uniquePackingVariants?: string[];
  uniqueOtherVariants?: string[];
  variants?: ProductVariant[];
  color?: string;
  size?: string;
  material?: string;
  packing?: string;
  other?: string;
};

export type Ads = {
  key?: string;
  title: string;
  credits: number;
  package: "Silver" | "Gold" | "Platinum";
  vendor: string;
  status: boolean;
  verticalBanners: {
    url: string;
    views: number;
    interaction: number;
    status: boolean;
  }[];
  horizontalBanners: {
    url: string;
    views: number;
    interaction: number;
    status: boolean;
  }[];
  audio: {
    url: string;
    listens: string;
    interaction: string;
    status: boolean;
  }[];
  ctaText: string;
  ctaAction: "Navigate" | "Link" | "None" | "Premium" | "Call";
  ctaTarget: string;
  language: string;
};

export type OrderItem = {
  quantity: number;
  image: string;
  name: string;
  price: number;
  currency: string;
  productId: string;
  status: "Pending" | "Confirmed" | "Dispatched" | "Delivered";
  vendor: string;
  dispatchedAt?: Date;
  deliveredAt?: Date;
  trackingId?: string;
  customFields?: {
    name: string;
    description?: string;
    value: string;
  }[];
  sku: string;
};

export type Address = {
  address: string;
  area: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  phone: string;
  isDefault: boolean;
  name: string;
};

export type Order = {
  key?: string;
  products: OrderItem[];
  currency: string;
  discount: number;
  paymentId: string;
  paymentStatus: "authorized" | "captured" | "failed";
  placedAt: Date;
  status: "Pending" | "Confirmed" | "Dispatched" | "Delivered";
  shipping: number;
  userId: string;
  coupon: string;
  amount: number;
  address: Address;
};
export enum ActivitySkills {
  LEFT_BRAIN = "Left Brain",
  RIGHT_BRAIN = "Right Brain",
  SENSORY = "Sensory",
  SOCIAL_EMOTION = "Social/Emotion",
  PHYSICAL = "Physical",
}
export type Activity = {
  key?: string;
  activityAgeFrom: number;
  activityAgeTo: number;
  activityDescr: string;
  activityMaterialsNeeded?: { name: string; productId?: string }[];
  activityImages: string[];
  activityObjects: string[];
  activityProducts: string[];
  activityRecommendedAge: number;
  activitySkills: ActivitySkills[];
  activityStatus: boolean;
  activityTitle: string;
  activityVideos: string[];
  activityTime: string;
  tags: string[];
  isPremium?: boolean;
};

export type HomeScreenBanner = {
  key?: string;
  bannerImage: string;
  bannerTitle: string;
  bannerDescr: string;
  bannerStatus: boolean;
  bannerType: "Podcast" | "Activity" | "Food" | "Info";
  bannerAction: "Navigate" | "Link" | "None";
  bannerTopic: string[];
  bannerTarget?: string;
};

export type ActivityHistory = {
  key?: string;
  timeStamp: Date;
  note?: string;
  rating?: number;
};

export type Profile = {
  key?: string;
  profileId: string;
  profileName: string;
  profileMeal: FoodType;
  profileDob: string;
  profileGender: "M" | "F";
  profileImage: string;
  profileStatus: boolean;
  storiesHeard?: number;
  activitiesCompleted?: number;
};

export enum FoodTime {
  LUNCH = "LN",
  BREAKFAST = "BF",
  DINNER = "DN",
  SNACK = "SN",
}

export enum FoodType {
  VEG = "Veg",
  NON_VEG = "Non-Veg",
  EGG = "Egg",
}

export enum FoodCusine {
  INDIAN = "Indian",
  INTERNATIONAL = "International",
}

export type Food = {
  key?: string;
  foodCusine: FoodCusine;
  foodImages: string[];
  foodIng: string;
  foodName: string;
  foodRecepe: string;
  foodStatus: boolean;
  foodTime: FoodTime[];
  foodType?: FoodType;
  foodVideos?: string[];
  foodPreparationTime: string;
  foodAgeFrom: number;
  foodAgeTo: number;
  tags: string[];
};

export type User = {
  uid: string;
  phone: string;
  createdAt: string;
  lastSignIn: string;
  lastRefresh: string;
  subscription: any;
  favouritePodcasts: string[];
  favouriteActivities: string[];
  favouriteFoods: string[];
  favouriteProducts: string[];
  addresses: Address[];
  revenueCatId?: string;
  tokens?: string[];
  isSubscriptionActive?: boolean;
  profiles: Profile[];
  crm: CRM;
};

export type CRM = {
  welcomeCall: boolean;
  followUpRequired: boolean;
  comments: { comment: string; date: Date; user: string }[];
  followUpDate: Date;
  welcomeCallDate: Date;
  uid?: string;
};

export type Notif = {
  title: string;
  message: string;
  topic: string;
  type: "Info" | "Activity" | "Food" | "Podcast" | "Product" | "Journal";
  target?: string;
  action?: "Navigate" | "Link" | "Premium" | "None";
  image?: string;
};
