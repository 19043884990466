window.Buffer = window.Buffer || require("buffer").Buffer;
import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
} from "@chakra-ui/react";
import { FontAwesome } from "@expo/vector-icons";
import { uuidv4 } from "@firebase/util";
import { ResizeMode, Video } from "expo-av";
import { manipulateAsync } from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { map, pick, set, uniq } from "lodash";
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  Switch,
  TouchableOpacity,
} from "react-native";
import { Dropdown } from "react-native-element-dropdown";
import { Provider } from "react-native-paper";
import S3FileUpload from "react-s3";
import { ToastContainer, toast } from "react-toastify";
import {
  ActivityIndicator,
  ChakraInput,
  Text,
  View,
} from "../components/Themed";
import Colors from "../constants/Colors";
import Styles from "../constants/Styles";
import { Product, ProductVariant, Vendor } from "../types";
import { _getAmountWithTax, getDefaultThumbnail } from "../utils/helper";
const ProductForm: React.FC<{
  selectedProduct?: Product;
  isVisble: boolean;
  setIsVisible: React.Dispatch<SetStateAction<boolean>>;
  onUpdated?: (product: Product) => void;
}> = ({ selectedProduct, isVisble, setIsVisible, onUpdated }) => {
  const video = React.useRef(null);
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [downloadableUploading, setDownloadableUploading] = useState(false);
  const [videos, setVideos] = useState<any[]>([]);
  const [images, setImages] = useState<string[]>(["Test"]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [VendorList, setVendorList] = useState<Vendor[]>([]);
  const [CategoryList, setCategoryList] = useState<[]>([]);
  const [product, setProduct] = useState<Product>(
    selectedProduct || {
      name: "",
      description: "",
      fromAgeInMonths: "",
      toAgeInMonths: "",
      price: "",
      currency: "INR",
      images: [],
      videos: [],
      inStock: true,
      vendor: "",
      category: "",
      sku: "",
      deliveryDays: "3-5",
      costPrice: "",
      isPersonalized: false,
      gst: 0,
      hasVariants: false,
      isDownloadable: false,
    }
  );
  const db = getFirestore();

  useEffect(() => {
    if (selectedProduct) {
      setProduct(selectedProduct);
    } else {
      setProduct({
        name: "",
        description: "",
        fromAgeInMonths: "",
        toAgeInMonths: "",
        price: "",
        currency: "INR",
        images: [],
        videos: [],
        inStock: true,
        vendor: "",
        category: "",
        sku: "",
        deliveryDays: "3-5",
        costPrice: "",
        isPersonalized: false,
        customFields: [],
        gst: 0,
        hasVariants: false,
        isDownloadable: false,
      });
    }
  }, [selectedProduct]);

  const onSubmit = useCallback(async () => {
    if (product.category === "" || !product.category) {
      toast("Please select category");
      return;
    }
    if (product.sku === "" || !product.sku) {
      toast("Please add a SKU");
      return;
    }
    if (product.hasVariants) {
      if ((product.variants?.length || 0) === 0) {
        toast("Please add atleast one variant");
        return;
      }

      if (
        (product.hasColorVariant && !product.color) ||
        (product.hasSizeVariant && !product.size) ||
        (product.hasMaterialVariant && !product.material) ||
        (product.hasPackingVariant && !product.packing) ||
        (product.hasOtherVariant && !product.other)
      ) {
        toast("Please fill variant name for the first variant");
        return;
      }
      if (
        product.variants?.some(
          (variant) =>
            (product.hasColorVariant && !variant.color) ||
            (product.hasSizeVariant && !variant.size) ||
            (product.hasMaterialVariant && !variant.material) ||
            (product.hasPackingVariant && !variant.packing) ||
            (product.hasOtherVariant && !variant.other)
        )
      ) {
        toast("Please fill all variant names for all variants");
        return;
      }
      if (
        product.variants?.some(
          (variant) =>
            !variant.price ||
            !variant.currency ||
            !variant.costPrice ||
            variant.gst === undefined ||
            !variant.deliveryDays ||
            !variant.images?.length
        )
      ) {
        toast("Please fill all variant details");
        return;
      }
    }
    const uniqueColorVariants = product.hasColorVariant
      ? uniq([product.color, ...map(product.variants, "color")])
      : [];
    const uniqueSizeVariants = product.hasSizeVariant
      ? uniq([product.size, ...map(product.variants, "size")])
      : [];
    const uniqueMaterialVariants = product.hasMaterialVariant
      ? uniq([product.material, ...map(product.variants, "material")])
      : [];
    const uniquePackingVariants = product.hasPackingVariant
      ? uniq([product.packing, ...map(product.variants, "packing")])
      : [];
    const uniqueOtherVariants = product.hasOtherVariant
      ? uniq([product.other, ...map(product.variants, "other")])
      : [];

    if (product.key) {
      setIsLoading(true);
      try {
        const activityRef = doc(db, "Products", product.key);

        await updateDoc(activityRef, {
          ...product,
          uniqueColorVariants,
          uniqueSizeVariants,
          uniqueMaterialVariants,
          uniquePackingVariants,
          uniqueOtherVariants,
          lastUpdatedBy: getAuth().currentUser?.uid,
          timestamp: serverTimestamp(),
        });
        setIsLoading(false);
        toast("Product Updated Successfully");
        setTimeout(() => {
          setIsVisible(false);
          onUpdated?.({
            ...product,
            uniqueColorVariants,
            uniqueSizeVariants,
            uniqueMaterialVariants,
            uniquePackingVariants,
            uniqueOtherVariants,
          } as Product);
        }, 2000);
      } catch (error) {
        setIsLoading(false);
        toast(error);
      }
    } else {
      setIsLoading(true);

      try {
        const docRef = await addDoc(collection(db, "Products"), {
          ...product,
          uniqueColorVariants,
          uniqueSizeVariants,
          uniqueMaterialVariants,
          uniquePackingVariants,
          uniqueOtherVariants,
          lastUpdatedBy: getAuth().currentUser?.uid,
          timestamp: serverTimestamp(),
        });
        await setDoc(docRef, { ...product, key: docRef.id }, { merge: true });
        setIsLoading(false);
        toast("Product Added Successfully");
        onUpdated?.({
          ...product,
          key: docRef.id,
          uniqueColorVariants,
          uniqueSizeVariants,
          uniqueMaterialVariants,
          uniquePackingVariants,
          uniqueOtherVariants,
          lastUpdatedBy: getAuth().currentUser?.uid,
          timestamp: serverTimestamp(),
        } as Product);
        setTimeout(() => {
          setIsVisible(false);
        }, 2000);
      } catch (error) {
        setIsLoading(false);
        toast(error);
        console.log("Errpr::", error);
      }
    }
  }, [db, product]);

  const handleUpload = useCallback(
    async (file: any, isDownloadable?: boolean) => {
      const S3_BUCKET = "monkitoxs3";
      const REGION = "us-east-2";
      const ACCESS_KEY = "AKIAZ2BTAGOCCLAMSDMD";
      const SECRET_ACCESS_KEY = "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";
      const config = {
        bucketName: S3_BUCKET,
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
      };
      setIsVideoUploading(true);
      S3FileUpload.uploadFile(file, config)
        .then((data: any) => {
          console.log(data);
          setProduct((currentValue) => {
            return {
              ...currentValue,
              [isDownloadable ? "downloadables" : "videos"]: [
                encodeURI(data.location),
              ],
            };
          });
          setIsVideoUploading(false);
          setDownloadableUploading(false);
        })
        .catch((err: any) => {
          console.log(err);
          setIsVideoUploading(false);
          alert("Something went wrong while uploading video file");
        });
    },
    []
  );

  const handleFileSelect = useCallback(
    (e: any, isDownloadable: boolean) => {
      if (isDownloadable) {
        setDownloadableUploading(true);
        handleUpload(e.target.files[0], true);
        return;
      }
      setIsVideoUploading(true);
      handleUpload(e.target.files[0]);
    },
    [handleUpload]
  );

  async function uploadImageAsync(uri: string) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662

    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 640 } }],
      { compress: 1 }
    );
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", manipResult.uri, true);
      xhr.send(null);
    });

    const fileRef = ref(getStorage(), uuidv4());
    await uploadBytes(fileRef, blob);

    // We're done with the blob, close and release it
    //blob.close();

    return await getDownloadURL(fileRef);
  }
  const pickImage = useCallback(
    async (variantIndex: number, index?: number) => {
      // No permissions request is necessary for launching the image library
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
      });

      console.log(result);

      if (!result.canceled) {
        const image_url = await uploadImageAsync(result.uri);

        if (variantIndex === -1) {
          setProduct((currentValue) => {
            const activityImages = currentValue.images || [];
            if (index === undefined) {
              activityImages.push(image_url);
            } else {
              activityImages[index] = image_url;
            }
            return { ...currentValue, images: activityImages };
          });
        } else {
          setProduct((currentValue) => {
            const variants = currentValue.variants || [];
            const variant = variants[variantIndex];
            const variantImages = variant.images || [];
            if (index === undefined) {
              variantImages.push(image_url);
            } else {
              variantImages[index] = image_url;
            }
            variants[variantIndex] = { ...variant, images: variantImages };
            return { ...currentValue, variants };
          });
        }
      }
    },
    []
  );

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };
  const handleRemoveVideo = (index: number) => {
    setVideos((prevVideos) => {
      const updatedVideos = [...prevVideos];
      updatedVideos.splice(index, 1);
      return updatedVideos;
    });
  };

  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);
      const q = query(collection(db, "Vendors"), orderBy("Name"));
      const vendors: any = [];
      const querySnapshot = await getDocs(q);

      for (const [index, doc] of querySnapshot.docs.entries()) {
        const _vendor = doc.data() as any;
        _vendor.key = doc.id;
        if (!_vendor.Thumbnail) {
          _vendor.Thumbnail = getDefaultThumbnail(index);
        }
        vendors.push(_vendor);
      }
      setVendorList(vendors);
      setIsLoading(false);
    }
    fetchMyAPI();
  }, [db]);

  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);
      getDoc(doc(db, "config", "productCategories")).then((categoriesDoc) => {
        if (categoriesDoc.exists()) {
          const data = categoriesDoc.data();
          const categories = data?.Categories || [];
          setCategoryList(categories);
        }
      });
      setIsLoading(false);
    }
    fetchMyAPI();
  }, [db]);

  const hasValidVariantSetup = useMemo(() => {
    return (
      product.hasVariants &&
      (product.hasColorVariant ||
        product.hasSizeVariant ||
        product.hasMaterialVariant ||
        product.hasPackingVariant ||
        product.hasOtherVariant)
    );
  }, [
    product.hasVariants,
    product.hasColorVariant,
    product.hasSizeVariant,
    product.hasMaterialVariant,
    product.hasPackingVariant,
    product.hasOtherVariant,
  ]);

  const updateValues = useCallback(
    (index: number, key: keyof ProductVariant, value: any) => {
      if (index === -1) {
        setProduct((currentProduct) => {
          return { ...currentProduct, [key]: value };
        });
        return;
      }
      setProduct((currentProduct) => {
        const variants = currentProduct.variants || [];
        variants[index] = {
          ...variants[index],
          [key]: value,
        };
        return { ...currentProduct, variants };
      });
    },
    []
  );

  const renderDetails = useCallback(
    (index: number) => {
      // -1 Index is the default variant

      return (
        <TabPanel>
          <View style={Styles.flexRowCenterSpaceBetween}>
            <View
              style={[
                Styles.flexRowCenter,
                hasValidVariantSetup && Styles.marginBottom,
              ]}
            >
              {product.hasColorVariant && (
                <ChakraInput
                  style={{ width: 200, marginRight: 20 }}
                  value={
                    (index === -1 ? product : product.variants?.[index])?.color
                  }
                  label="Color"
                  onChangeText={(value: string) =>
                    updateValues(index, "color", value)
                  }
                  width={"75"}
                />
              )}
              {product.hasSizeVariant && (
                <ChakraInput
                  style={{ width: 200, marginRight: 20 }}
                  value={
                    (index === -1 ? product : product.variants?.[index])?.size
                  }
                  label="Size"
                  onChangeText={(value: string) =>
                    updateValues(index, "size", value)
                  }
                  width={"75"}
                />
              )}
              {product.hasMaterialVariant && (
                <ChakraInput
                  style={{ width: 200, marginRight: 20 }}
                  value={
                    (index === -1 ? product : product.variants?.[index])
                      ?.material
                  }
                  label="Material"
                  onChangeText={(value: string) =>
                    updateValues(index, "material", value)
                  }
                  width={"75"}
                />
              )}
              {product.hasPackingVariant && (
                <ChakraInput
                  style={{ width: 200, marginRight: 20 }}
                  value={
                    (index === -1 ? product : product.variants?.[index])
                      ?.packing
                  }
                  label="Packing"
                  onChangeText={(value: string) =>
                    updateValues(index, "packing", value)
                  }
                  width={"75"}
                />
              )}
              {product.hasOtherVariant && (
                <ChakraInput
                  style={{ width: 200, marginRight: 20 }}
                  value={
                    (index === -1 ? product : product.variants?.[index])?.other
                  }
                  label="Other"
                  onChangeText={(value: string) =>
                    updateValues(index, "other", value)
                  }
                  width={"75"}
                />
              )}
            </View>
            {index !== -1 && (
              <TouchableOpacity
                onPress={() => {
                  setProduct((currentProduct) => {
                    const variants = currentProduct.variants || [];
                    variants.splice(index, 1);
                    return { ...currentProduct, variants };
                  });
                }}
              >
                <FontAwesome name="trash" color={Colors.red} size={20} />
              </TouchableOpacity>
            )}
          </View>
          <View style={Styles.flexRowCenterSpaceBetween}>
            <View style={{ flex: 1, padding: 10 }}>
              <View
                style={[Styles.flexRowCenterSpaceBetween, { marginTop: 5 }]}
              >
                <View>
                  <Text style={Styles.onboardLabel}>In Stock</Text>

                  <Switch
                    value={
                      (index === -1 ? product : product.variants?.[index])
                        ?.inStock
                    }
                    onValueChange={(value) =>
                      updateValues(index, "inStock", value)
                    }
                  >
                    In Stock
                  </Switch>
                </View>
              </View>
              <ChakraInput
                style={{ marginTop: 10 }}
                value={
                  (index === -1 ? product : product.variants?.[index])?.sku
                }
                label="SKU"
                onChangeText={(value: string) =>
                  updateValues(index, "sku", value)
                }
                width={"150"}
              />
              <ChakraInput
                style={{ marginTop: 10 }}
                value={
                  (index === -1 ? product : product.variants?.[index])
                    ?.fromAgeInMonths
                }
                label="Age From (In Months)"
                onChangeText={(value: string) =>
                  updateValues(index, "fromAgeInMonths", value)
                }
                width={"150"}
              />
              <ChakraInput
                style={{ marginTop: 10 }}
                value={
                  (index === -1 ? product : product.variants?.[index])
                    ?.toAgeInMonths
                }
                label="Age To (In Months)"
                onChangeText={(value: string) =>
                  updateValues(index, "toAgeInMonths", value)
                }
                width={"150"}
              />
              <ChakraInput
                value={
                  (index === -1 ? product : product.variants?.[index])
                    ?.deliveryDays
                }
                label="Delivery Days"
                onChangeText={(value: string) =>
                  updateValues(index, "deliveryDays", value)
                }
                width={"150"}
              />
            </View>
            <View style={{ flex: 1, padding: 10 }}>
              <ChakraInput
                value={
                  (index === -1 ? product : product.variants?.[index])?.currency
                }
                label="Currency"
                onChangeText={(value: string) =>
                  updateValues(index, "currency", value)
                }
                width={"150"}
              />
              <ChakraInput
                style={{ marginTop: 10 }}
                value={
                  (index === -1 ? product : product.variants?.[index])
                    ?.costPrice || ""
                }
                label="Cost Price (Without GST)"
                onChangeText={(value: string) => {
                  const numericText = value.replace(/[^0-9]/g, "");
                  const truncatedText = numericText.slice(0, 6);
                  updateValues(index, "costPrice", truncatedText);
                }}
                width={"150"}
              />

              <ChakraInput
                style={{ marginTop: 10 }}
                value={
                  (index === -1 ? product : product.variants?.[index])?.price
                }
                label="Selling Price (Without GST)"
                onChangeText={(value: string) => {
                  const numericText = value.replace(/[^0-9]/g, "");
                  const truncatedText = numericText.slice(0, 6);

                  updateValues(index, "price", truncatedText);
                }}
                width={"150"}
              />
              <ChakraInput
                style={{ marginTop: 10 }}
                value={
                  (index === -1
                    ? product
                    : product.variants?.[index]
                  )?.gst?.toString() || 0
                }
                label="GST %"
                onChangeText={(value: string) => {
                  const numericText = value.replace(/[^0-9]/g, "");
                  const truncatedText = numericText.slice(0, 2);
                  updateValues(index, "gst", Number(truncatedText));
                }}
                width={"150"}
              />
              <Text style={Styles.marginTop}>
                Final Selling Price:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {_getAmountWithTax(
                    index === -1 ? product : product.variants?.[index]
                  )}
                </Text>
              </Text>
            </View>
            <View style={{ flex: 3, padding: 10 }}>
              <View>
                <View style={Styles.flexRowCenterSpaceBetween}>
                  <Text
                    style={{
                      color: Colors.black,
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {"Product Images"}
                  </Text>
                  {index > -1 && (
                    <TouchableOpacity
                      style={{
                        backgroundColor: "transparent",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onPress={() => {
                        setProduct((currentProduct) => {
                          const variants = currentProduct.variants || [];
                          const currentVariant = variants[index];
                          currentVariant.images =
                            index === 0
                              ? currentProduct.images
                              : variants[index - 1].images;
                          variants[index] = currentVariant;

                          return { ...currentProduct, variants };
                        });
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.primary,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        {"Copy from prev variant"}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
                {product && (
                  <View style={{ maxWidth: "80%" }}>
                    <FlatList
                      data={
                        (index === -1 ? product : product.variants?.[index])
                          ?.images
                      }
                      horizontal
                      renderItem={(image) => (
                        <TouchableOpacity
                          style={{
                            marginLeft: 20,
                            marginTop: 30,
                            marginBottom: 20,
                            alignItems: "center",
                          }}
                          onPress={() => pickImage(index, image.index)}
                        >
                          <Image
                            source={{ uri: image.item }}
                            style={{ width: 100, height: 100 }}
                          />
                        </TouchableOpacity>
                      )}
                      keyExtractor={(item) => item}
                      ListFooterComponent={() => (
                        <TouchableOpacity
                          style={{
                            marginLeft: 20,
                            marginTop: 30,
                            marginBottom: 20,
                            alignItems: "center",
                          }}
                          onPress={() => pickImage(index)}
                        >
                          <FontAwesome
                            name="upload"
                            color={Colors.primary}
                            size={100}
                          />
                          <Text style={{ color: Colors.black }}>
                            Upload Image
                          </Text>
                        </TouchableOpacity>
                      )}
                    />
                  </View>
                )}

                {product && (
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        alignSelf: "flex-start",
                        marginVertical: 10,
                        marginLeft: 10,
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.black,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        {"Product Video"}
                      </Text>
                      {(index === -1 ? product : product.variants?.[index])
                        ?.videos?.[0] ? (
                        <Video
                          ref={video}
                          style={{
                            width: 200,
                            height: 200,
                            marginVertical: 10,
                          }}
                          videoStyle={{ width: 200, height: 200 }}
                          //  style={styles.video}
                          source={{
                            uri: product.images?.[0],
                          }}
                          useNativeControls
                          resizeMode={ResizeMode.CONTAIN}
                          //  isLooping
                          //  onPlaybackStatusUpdate={(status) => setStatus(() => status)}
                        />
                      ) : (
                        <Text
                          style={{
                            color: Colors.grey,
                            fontSize: 16,
                            marginTop: 20,
                          }}
                        >
                          {"No Video attached"}
                        </Text>
                      )}
                      {isVideoUploading ? (
                        <ActivityIndicator
                          isLoading
                          size="large"
                          color={Colors.primary}
                        />
                      ) : (
                        <input
                          type="file"
                          onChange={(e) => handleFileSelect(e, false)}
                        />
                      )}
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
        </TabPanel>
      );
    },
    [product, pickImage, handleFileSelect, isVideoUploading]
  );

  if (!isVisble) {
    return null;
  }

  return (
    <Modal
      isOpen={isVisble}
      onClose={() => setIsVisible(false)}
      size={"full"}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Provider>
            <ToastContainer />
            <ScrollView showsVerticalScrollIndicator={true}>
              <View style={Styles.onboardContainer}>
                <View style={Styles.productFormContainer}>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ flex: 2 }}>
                      <ChakraInput
                        value={product.name}
                        label="Name"
                        onChangeText={(value: string) => {
                          setProduct({ ...product, name: value });
                        }}
                        width="40%"
                      />
                      <Flex direction={"column"} mt={2}>
                        <Text style={{ color: Colors.black, marginBottom: 5 }}>
                          Description
                        </Text>
                        <Textarea
                          mt={1}
                          value={product.description}
                          placeholder="Description"
                          onChange={(event) => {
                            setProduct({
                              ...product,
                              description: event.target.value,
                            });
                          }}
                          width="90%"
                          height="120px"
                        />
                      </Flex>
                    </View>
                    <View style={{ flex: 2 }}>
                      <View style={Styles.flexRowCenterSpaceBetween}>
                        <View style={{ marginRight: 25 }}>
                          <Text style={Styles.onboardLabel}>Vendor</Text>

                          <Dropdown
                            style={Styles.dropdown}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            data={VendorList}
                            maxHeight={300}
                            labelField="Name"
                            valueField="key"
                            placeholder="Select item"
                            value={VendorList.find(
                              (vendor) => vendor.key === product.vendor
                            )}
                            onChange={(vendor) => {
                              setProduct({
                                ...product,
                                vendor: vendor.key || "",
                              });
                            }}
                          />
                        </View>

                        <View style={Styles.columnStyle}>
                          <Text style={Styles.onboardLabel}>Category</Text>

                          <Dropdown
                            style={Styles.dropdown}
                            placeholderStyle={styles.placeholderStyle}
                            selectedTextStyle={styles.selectedTextStyle}
                            data={CategoryList.map((category: any) => {
                              return { key: category, Name: category };
                            })}
                            maxHeight={300}
                            labelField="Name"
                            valueField="key"
                            placeholder="Select item"
                            value={product.category}
                            onChange={(category) => {
                              console.log(category);
                              setProduct({
                                ...product,
                                category: category.key || "",
                              });
                            }}
                          />
                        </View>
                        <View>
                          <Text style={Styles.onboardLabel}>
                            Can be personalized
                          </Text>

                          <Switch
                            value={product.isPersonalized}
                            onValueChange={(value) => {
                              setProduct({ ...product, isPersonalized: value });
                            }}
                          >
                            In Stock
                          </Switch>
                        </View>
                      </View>
                      <Flex direction={"column"} mt={0}>
                        <Text style={{ color: Colors.black, marginBottom: 5 }}>
                          Specifications
                        </Text>
                        <Textarea
                          mt={1}
                          value={product.specifications}
                          placeholder="Specifications"
                          onChange={(event) => {
                            setProduct({
                              ...product,
                              specifications: event.target.value,
                            });
                          }}
                          width="90%"
                          height="120px"
                        />
                      </Flex>
                    </View>
                  </View>
                  <View
                    style={[
                      Styles.flexRow,
                      Styles.marginTop,
                      { alignItems: "flex-start" },
                    ]}
                  >
                    <View style={{ width: 300 }}>
                      <Checkbox
                        colorScheme="green"
                        isChecked={product.hasVariants}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            hasVariants: e.target.checked,
                          });
                        }}
                      >
                        Has Variants
                      </Checkbox>
                      {product.hasVariants && (
                        <View style={[Styles.marginTop, Styles.marginLeft]}>
                          <Checkbox
                            colorScheme="blue"
                            isChecked={product.hasColorVariant}
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                hasColorVariant: e.target.checked,
                              });
                            }}
                          >
                            Color
                          </Checkbox>
                          <Checkbox
                            colorScheme="blue"
                            isChecked={product.hasSizeVariant}
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                hasSizeVariant: e.target.checked,
                              });
                            }}
                          >
                            Size
                          </Checkbox>
                          <Checkbox
                            colorScheme="blue"
                            isChecked={product.hasMaterialVariant}
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                hasMaterialVariant: e.target.checked,
                              });
                            }}
                          >
                            Material
                          </Checkbox>
                          <Checkbox
                            colorScheme="blue"
                            isChecked={product.hasPackingVariant}
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                hasPackingVariant: e.target.checked,
                              });
                            }}
                          >
                            Packing
                          </Checkbox>
                          <Checkbox
                            colorScheme="blue"
                            isChecked={product.hasOtherVariant}
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                hasOtherVariant: e.target.checked,
                              });
                            }}
                          >
                            Other
                          </Checkbox>
                        </View>
                      )}
                    </View>
                    <View>
                      <TouchableOpacity
                        style={{
                          backgroundColor: "transparent",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onPress={() => {
                          if (product.customFields?.length >= 3) {
                            toast("Maximum 3 custom fields allowed");
                            return;
                          }
                          setProduct({
                            ...product,
                            customFields: [
                              ...(product.customFields || []),
                              { name: "", description: "" },
                            ],
                          });
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.primary,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          {"+ Add Custom Fields"}
                        </Text>
                      </TouchableOpacity>
                      <View style={[Styles.flexRowCenter, Styles.marginTop]}>
                        {product.customFields?.map((field, index) => (
                          <View
                            key={index}
                            style={[
                              Styles.marginTop,
                              Styles.marginRight,
                              {
                                borderColor: Colors.grey,
                                borderWidth: 1,
                                padding: 10,
                                borderRadius: 10,
                              },
                            ]}
                          >
                            <ChakraInput
                              value={field.name}
                              label="Name"
                              onChangeText={(value: string) => {
                                const customFields = product.customFields || [];
                                customFields[index].name = value;
                                setProduct({ ...product, customFields });
                              }}
                              width={"150"}
                            />
                            <ChakraInput
                              value={field.description}
                              label="Description"
                              onChangeText={(value: string) => {
                                const customFields = product.customFields || [];
                                customFields[index].description = value;
                                setProduct({ ...product, customFields });
                              }}
                              width={"150"}
                            />
                            <TouchableOpacity
                              style={{
                                backgroundColor: "transparent",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 5,
                                alignSelf: "flex-end",
                              }}
                              onPress={() => {
                                const customFields = product.customFields || [];
                                customFields.splice(index, 1);
                                setProduct({ ...product, customFields });
                              }}
                            >
                              <FontAwesome
                                name="trash"
                                color={Colors.red}
                                size={20}
                              />
                            </TouchableOpacity>
                          </View>
                        ))}
                      </View>
                    </View>
                    <View style={Styles.marginLeft25}>
                      <Text style={Styles.onboardLabel}>Is Downloadable</Text>

                      <Switch
                        value={product.isDownloadable}
                        onValueChange={(value) => {
                          setProduct({ ...product, isDownloadable: value });
                        }}
                      >
                        Is Downloadable
                      </Switch>
                      {(product.downloadables?.length || 0) > 0 && (
                        <View style={Styles.marginTop}>
                          <Text>
                            {product?.downloadables?.[0].replace(
                              /^.*[\\/]/,
                              ""
                            )}
                          </Text>
                        </View>
                      )}
                      {product.isDownloadable && (
                        <View style={Styles.marginTop}>
                          {downloadableUploading ? (
                            <ActivityIndicator
                              isLoading
                              size="large"
                              color={Colors.primary}
                            />
                          ) : (
                            <input
                              type="file"
                              onChange={(e) => handleFileSelect(e, true)}
                            />
                          )}
                        </View>
                      )}
                    </View>
                  </View>
                  <Tabs>
                    <TabList>
                      <Tab>
                        {hasValidVariantSetup
                          ? product.color ||
                            product.size ||
                            product.material ||
                            product.packing ||
                            product.other
                            ? (product.color || "") +
                              " " +
                              (product.size || "") +
                              " " +
                              (product.material || "") +
                              " " +
                              (product.packing || "") +
                              " " +
                              (product.other || "")
                            : "Variant 1"
                          : "Details"}
                      </Tab>
                      {product.variants?.map((variant, index) => {
                        return (
                          <Tab>
                            {variant.color ||
                            variant.size ||
                            variant.material ||
                            variant.packing ||
                            variant.other
                              ? (variant.color || "") +
                                " " +
                                (variant.size || "") +
                                " " +
                                (variant.material || "") +
                                " " +
                                (variant.packing || "") +
                                " " +
                                (variant.other || "")
                              : "Variant " + (index + 2)}
                          </Tab>
                        );
                      })}
                      {hasValidVariantSetup && (
                        <Tab
                          color={"darkorange"}
                          onClick={() =>
                            setProduct((cv) => {
                              return {
                                ...cv,
                                variants: [
                                  ...(cv.variants || []),
                                  {
                                    color: "",
                                    size: "",
                                    material: "",
                                    packing: "",
                                    other: "",
                                    images: [],
                                    inStock: true,
                                    videos: [],
                                    ...pick(
                                      cv.variants?.length
                                        ? cv.variants[cv.variants.length - 1]
                                        : product,
                                      [
                                        "price",
                                        "costPrice",
                                        "fromAgeInMonths",
                                        "toAgeInMonths",
                                        "deliveryDays",
                                        "gst",
                                        "currency",
                                        "sku",
                                      ]
                                    ),
                                  },
                                ],
                              };
                            })
                          }
                        >
                          + Add Variant
                        </Tab>
                      )}
                    </TabList>

                    <TabPanels>
                      {renderDetails(-1)}
                      {product.variants?.map((variant, index) => {
                        return renderDetails(index);
                      })}
                    </TabPanels>
                  </Tabs>
                </View>
              </View>
            </ScrollView>
          </Provider>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              if (selectedProduct) {
                setIsLoading(true);
                deleteDoc(doc(db, "Products", selectedProduct.key || ""))
                  .then(() => {
                    setIsLoading(false);
                    toast("Product Deleted Successfully");
                    setIsVisible(false);
                    onUpdated?.(selectedProduct);
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    toast(error);
                  });
              } else {
                setIsVisible(false);
              }
            }}
            isLoading={isLoading}
          >
            Delete
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => setIsVisible(false)}
            isLoading={isLoading}
          >
            Close
          </Button>
          <Button colorScheme="green" onClick={onSubmit} isLoading={isLoading}>
            {selectedProduct ? "Save Changes" : "Create Product"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ProductForm;
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
    backgroundColor: "transparent",
  },
});
