window.Buffer = window.Buffer || require("buffer").Buffer;
import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { FlatList, ScrollView, StyleSheet } from "react-native";
import { Provider } from "react-native-paper";
import { ToastContainer } from "react-toastify";
import { CRM, Profile, User } from "../types";
import { Text, View } from "../components/Themed";
import Styles from "../constants/Styles";
import { DATE_FORMAT } from "../constants/Config";
import moment from "moment";
import {
  _getTimeStampString,
  getFormattedPeriodSinceBirth,
} from "../utils/helper";
import Colors from "../constants/Colors";
const UserDetails: React.FC<{
  selectedUser?: User;
  isVisble: boolean;
  setIsVisible: React.Dispatch<SetStateAction<boolean>>;
}> = ({ selectedUser, isVisble, setIsVisible }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<User>();
  const [crmInfo, setCrmInfo] = useState<CRM>();
  const [comment, setComment] = useState<string>("");

  const db = getFirestore();

  useEffect(() => {
    setUserDetails(undefined);
    setComment("");
    setCrmInfo(undefined);
    if (selectedUser?.uid) {
      getDoc(doc(db, "users", selectedUser.uid)).then((doc) => {
        if (doc.exists()) {
          console.warn("Document data:", doc.data());
          setUserDetails((currentValue) => {
            return { ...currentValue, ...doc.data() };
          });
        }
      });

      getDocs(collection(db, "users", selectedUser.uid, "profiles")).then(
        (querySnapshot) => {
          const profiles: Profile[] = [];
          querySnapshot.forEach((doc) => {
            profiles.push(doc.data() as Profile);
          });
          setUserDetails((currentValue) => {
            return { ...currentValue, profiles };
          });
        }
      );

      getDoc(doc(db, "CRM", selectedUser.uid)).then((doc) => {
        if (doc.exists()) {
          console.warn("CRM data:", doc.data());
          setCrmInfo({
            ...doc.data(),
            followUpRequired: !!doc.data().followUpRequired,
            welcomeCall: !!doc.data().welcomeCall,
          } as CRM);
        }
      });
    }
  }, [selectedUser?.uid]);

  const saveCRMInfo = useCallback(() => {
    if (!selectedUser?.uid) {
      return;
    }
    setIsLoading(true);
    const comments = crmInfo?.comments || [];
    if (comment && comment.length > 0) {
      comments.unshift({
        comment,
        date: moment().toDate(),
        user: selectedUser.uid,
      });
    }
    setDoc(
      doc(db, "CRM", selectedUser.uid),
      {
        ...crmInfo,
        comments,
      },
      { merge: true }
    ).then(() => {
      setIsLoading(false);
      setCrmInfo({
        ...crmInfo,
        comments: [
          {
            comment,
            user: selectedUser.uid,
          },
          ...(crmInfo?.comments || []),
        ],
      });
      setComment("");
    });
  }, [crmInfo, comment, selectedUser?.uid]);

  if (!isVisble || !selectedUser) {
    return null;
  }

  return (
    <Modal
      isOpen={isVisble}
      onClose={() => setIsVisible(false)}
      size={"xl"}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Provider>
            <ToastContainer />
            <ScrollView showsVerticalScrollIndicator={true}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Text>{selectedUser?.phone}</Text>
                  <Checkbox
                    colorScheme="blue"
                    isChecked={!!userDetails?.isSubscriptionActive}
                    isReadOnly={true}
                  >
                    Subscription Active
                  </Checkbox>
                </View>
                <View style={{ alignItems: "flex-end" }}>
                  <Text>{`Created On: ${moment(selectedUser?.createdAt).format(
                    DATE_FORMAT
                  )}`}</Text>
                  <Text>{`Last Sign In: ${moment(
                    selectedUser?.lastSignIn
                  ).format(DATE_FORMAT)}`}</Text>
                  <Text>{`Last Used: ${moment(selectedUser?.lastRefresh).format(
                    DATE_FORMAT
                  )}`}</Text>
                </View>
              </View>
              <View style={Styles.marginTop}>
                <Text
                  style={{ fontWeight: "bold" }}
                >{`# of Kids: ${userDetails?.profiles?.length}`}</Text>
                {userDetails?.profiles?.map((profile) => (
                  <View key={profile.profileId}>
                    <Text>{`${
                      profile.profileName
                    } - ${getFormattedPeriodSinceBirth(
                      profile.profileDob
                    )}`}</Text>
                  </View>
                ))}
                <Checkbox
                  mt={2}
                  colorScheme="blue"
                  isChecked={crmInfo?.welcomeCall}
                  onChange={(e) => {
                    setCrmInfo((currentValue) => {
                      return {
                        ...currentValue,
                        welcomeCall: e.target.checked,
                        welcomeCallDate:
                          currentValue?.welcomeCallDate || serverTimestamp(),
                      };
                    });
                  }}
                >
                  Welcome Call
                </Checkbox>
                <Checkbox
                  colorScheme="blue"
                  isChecked={crmInfo?.followUpRequired}
                  onChange={(e) => {
                    setCrmInfo((currentValue) => {
                      return {
                        ...currentValue,
                        followUpRequired: e.target.checked,
                      };
                    });
                  }}
                >
                  Follow-Up Required
                </Checkbox>
                <Textarea
                  mt={2}
                  placeholder="Comments"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />

                <FlatList
                  data={crmInfo?.comments}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={({ item }) => (
                    <View style={[Styles.marginTop]}>
                      <Text>{item.comment}</Text>
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 10,
                          color: Colors.grey,
                        }}
                      >
                        {_getTimeStampString(item.date)}
                      </Text>
                    </View>
                  )}
                />
              </View>
            </ScrollView>
          </Provider>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="green"
            mr={3}
            onClick={() => saveCRMInfo()}
            isLoading={isLoading}
          >
            Save
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => setIsVisible(false)}
            isLoading={isLoading}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default UserDetails;
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
    backgroundColor: "transparent",
  },
});
