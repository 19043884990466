export const S3_BUCKET = "monkitoxs3";
export const REGION = "us-east-2";
export const ACCESS_KEY = "AKIAZ2BTAGOCCLAMSDMD";
export const SECRET_ACCESS_KEY = "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";
export const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};
export const DATE_FORMAT = "DD MMM YYYY hh:mm A";
