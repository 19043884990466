/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */

/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import React, { useCallback, useEffect, useState } from "react";

import { FontAwesome } from "@expo/vector-icons";
import { uuidv4 } from "@firebase/util";
import { ResizeMode, Video } from "expo-av";
import { manipulateAsync } from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Checkbox, Provider } from "react-native-paper";
import S3FileUpload from "react-s3";

import MultiSelectInput from "../components/MultiSelectInput";
import TextInput from "../components/TextInput";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import useColorScheme from "../hooks/useColorScheme";
import { Activity, ActivitySkills, RootTabScreenProps } from "../types";
import { _searchList, getDefaultThumbnail } from "../utils/helper";

//const analytics = getAnalytics(app);

export default function AdminActivityList({
  navigation,
}: RootTabScreenProps<"Discover">) {
  const theme = useColorScheme();

  const [isLoading, setIsLoading] = useState(true);
  const [userPhone, setUserPhone] = useState<string | undefined | null>();
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const [filteredActivityList, setFilteredActivityList] =
    useState<Activity[]>();
  const [activity, setActivity] = useState<Activity>({
    // activityVideos: ['https://monkitoxs3.s3.amazonaws.com/Sample Video.mp4'],
  } as Activity);
  const [searchText, setSearchText] = useState<string>();
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const video = React.useRef(null);
  const db = getFirestore();

  useEffect(() => {
    if (getAuth().currentUser) {
      setUserPhone(getAuth().currentUser?.phoneNumber);
    }
  }, []);

  useEffect(() => {
    if (searchText && searchText !== "") {
      setFilteredActivityList(_searchList(activityList, searchText));
    } else {
      setFilteredActivityList(undefined);
    }
  }, [activityList, searchText]);

  async function uploadImageAsync(uri: string) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662

    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 250 } }],
      { compress: 1 }
    );
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", manipResult.uri, true);
      xhr.send(null);
    });

    const fileRef = ref(getStorage(), uuidv4());
    await uploadBytes(fileRef, blob);

    // We're done with the blob, close and release it
    //blob.close();

    return await getDownloadURL(fileRef);
  }

  const pickImage = useCallback(
    async (index?: number) => {
      // No permissions request is necessary for launching the image library
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
      });

      console.log(result);

      if (!result.canceled) {
        const image_url = await uploadImageAsync(result.uri);
        console.log(image_url);
        const activityImages = activity?.activityImages || [];
        if (index === undefined) {
          activityImages.push(image_url);
        } else {
          activityImages[index] = image_url;
        }
        setActivity({
          ...activity,
          activityImages: activityImages,
        } as Activity);
      }
    },
    [activity]
  );

  const saveChanges = useCallback(async () => {
    if (!activity) {
      return;
    }
    if (
      !activity.activityTitle ||
      !activity.activityTime ||
      !activity.activityDescr ||
      !activity.activityAgeTo ||
      !activity.activityRecommendedAge ||
      !((activity.activitySkills?.length || 0) > 0)
    ) {
      alert("Enter all mandatory fields");
      return;
    }

    const TagsList = activity.tags?.map((s) => s.trim()) || [];

    if (activity.key) {
      const activityRef = doc(db, "activities", activity.key);

      await updateDoc(activityRef, {
        ...activity,
        lastUpdatedBy: getAuth().currentUser?.uid,
        Tags: TagsList,
      });

      const updatedList = activityList;
      const index = activityList.findIndex((x) => x.key === activity.key);
      updatedList[index] = { ...activity, tags: TagsList };
      setActivityList(updatedList);

      const updatedFilteredList = filteredActivityList;
      const filteredIndex = filteredActivityList?.findIndex(
        (x) => x.key === activity.key
      );

      if (
        filteredIndex !== undefined &&
        filteredIndex >= 0 &&
        updatedFilteredList
      ) {
        updatedFilteredList[filteredIndex] = { ...activity, tags: TagsList };
        setFilteredActivityList(updatedFilteredList);
      }

      alert("Updated Successfully");
    } else {
      const activityRef = await addDoc(collection(db, "activities"), {
        ...activity,
        tags: TagsList,
        lastUpdatedBy: getAuth().currentUser?.uid,
        createdAt: serverTimestamp(),
      });
      const updatedList = activityList;
      updatedList.push({ ...activity, tags: TagsList, key: activityRef.id });
      setActivityList(updatedList);
      setActivity({ ...activity, tags: TagsList, key: activityRef.id });

      const updatedFilteredList = filteredActivityList;
      updatedFilteredList?.push({
        ...activity,
        tags: TagsList,
        key: activityRef.id,
      });
      setFilteredActivityList(updatedFilteredList);
      alert("Created Successfully");
    }
  }, [db, filteredActivityList, activity, activityList]);

  const listItem = useCallback(
    ({
      item,
      onPress,
    }: {
      item: Activity;
      index: number;
      onPress?: (item: Activity) => void;
    }) => {
      return (
        <TouchableOpacity
          style={{
            ...Layout.listItemSize,
            marginLeft: 5,
            flexDirection: "row",
            marginTop: 5,
            maxWidth: Layout.window.height / 2,
          }}
          onPress={() => {
            if (onPress) {
              return onPress?.(item);
            }

            setActivity(item);
          }}
        >
          <Image
            source={{ uri: item.activityImages[0] }}
            style={{ ...Layout.thumbnailSmall, borderRadius: 5 }}
          />

          <View style={{ padding: 5 }}>
            <Text style={[Styles.textTileTitle]}>{item.activityTitle}</Text>
            <View style={Styles.row}>
              <Text
                style={Styles.textSubTitle}
              >{`${item.activityAgeFrom} - ${item.activityAgeTo} months`}</Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    },
    []
  );

  const handleUpload = useCallback(async (file: any) => {
    const S3_BUCKET = "monkitoxs3";
    const REGION = "us-east-2";
    const ACCESS_KEY = "AKIAZ2BTAGOCCLAMSDMD";
    const SECRET_ACCESS_KEY = "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";
    const config = {
      bucketName: S3_BUCKET,
      region: REGION,
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
    };
    setIsVideoUploading(true);
    S3FileUpload.uploadFile(file, config)
      .then((data: any) => {
        console.log(data);
        setActivity((currentValue) => {
          return {
            ...currentValue,
            activityVideos: [encodeURI(data.location)],
          };
        });
        setIsVideoUploading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsVideoUploading(false);
        alert("Something went wrong while uploading video file");
      });
  }, []);

  const handleFileSelect = useCallback(
    (e) => {
      setIsVideoUploading(true);
      handleUpload(e.target.files[0]);
    },
    [handleUpload]
  );

  const renderList = useCallback(() => {
    return (
      <FlatList
        data={filteredActivityList || activityList}
        style={{
          marginTop: 10,
          paddingLeft: 50,
          height: Layout.window.height * 0.75,
          //marginBottom: 100 + ((playingNow.isPlayingNow || playingNow.Title || playingNow.live) ? 50 : 0)
        }}
        ListHeaderComponent={
          <View>
            <View style={Styles.flexRowCenterSpaceAround}>
              <Text style={{ fontSize: 30 }}>
                {activityList.length} Activities
              </Text>
              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  backgroundColor: Colors.primary,
                  borderRadius: 5,
                  width: 200,
                  alignSelf: "center",
                  marginVertical: 20,
                }}
                onPress={() =>
                  setActivity({
                    activityTitle: "",
                    activityDescr: "",
                    activityAgeFrom: 0,
                    activityAgeTo: 0,
                    activityRecommendedAge: 0,
                    activitySkills: [],
                    activityImages: [],
                    activityVideos: [],
                    activityStatus: true,
                    activityObjects: [],
                    activityProducts: [],
                    tags: [],
                    isPremium: true,
                    activityTime: "",
                  } as Activity)
                }
              >
                <Text style={{ fontSize: 20, textAlign: "center" }}>
                  Add New Activity
                </Text>
              </TouchableOpacity>
            </View>
            <TextInput
              value={searchText}
              label={"Search"}
              onChangeText={setSearchText}
            />
          </View>
        }
        keyExtractor={(item) => item.key || ""}
        renderItem={listItem}
      />
    );
  }, [filteredActivityList, listItem, activityList, searchText]);

  const renderDetails = useCallback(() => {
    return (
      <View
        style={{
          width: Layout.window.width / 2,
          borderWidth: 2,
          borderColor: Colors.primary,
          marginLeft: 10,
        }}
      >
        {!activity && (
          <View style={styles.container}>
            <Text style={{ fontSize: 30 }}>
              Select an Activity to view the details
            </Text>
          </View>
        )}
        {activity && (
          <View>
            <FlatList
              data={activity.activityImages}
              horizontal
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  style={{
                    marginLeft: 20,
                    marginTop: 30,
                    marginBottom: 20,
                    alignItems: "center",
                  }}
                  onPress={() => pickImage(index)}
                >
                  <Image
                    source={{ uri: item }}
                    style={{ width: 100, height: 100 }}
                  />
                </TouchableOpacity>
              )}
              keyExtractor={(item) => item}
              ListFooterComponent={() => (
                <TouchableOpacity
                  style={{
                    marginLeft: 20,
                    marginTop: 30,
                    marginBottom: 20,
                    alignItems: "center",
                  }}
                  onPress={() => pickImage()}
                >
                  <FontAwesome
                    name="upload"
                    color={Colors.primary}
                    size={100}
                  />
                  <Text>Upload Image</Text>
                </TouchableOpacity>
              )}
            />
          </View>
        )}

        {activity && (
          <View>
            <View
              style={{
                flex: 2,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  alignSelf: "flex-start",
                  marginVertical: 10,
                  marginLeft: 10,
                }}
              >
                <Text style={{ color: Colors.primary, fontSize: 16 }}>
                  {"Activity Video"}
                </Text>
                {activity.activityVideos?.[0] ? (
                  <Video
                    ref={video}
                    style={{ width: 200, height: 200, marginVertical: 10 }}
                    videoStyle={{ width: 200, height: 200 }}
                    //  style={styles.video}
                    source={{
                      uri: activity.activityVideos?.[0],
                    }}
                    useNativeControls
                    resizeMode={ResizeMode.CONTAIN}
                    //  isLooping
                    //  onPlaybackStatusUpdate={(status) => setStatus(() => status)}
                  />
                ) : (
                  <Text
                    style={{ color: Colors.grey, fontSize: 16, marginTop: 20 }}
                  >
                    {"No Video attached"}
                  </Text>
                )}
                {isVideoUploading ? (
                  <ActivityIndicator
                    isLoading
                    size="large"
                    color={Colors[theme].primary}
                  />
                ) : (
                  <input type="file" onChange={handleFileSelect} />
                )}
              </View>
            </View>
            <TextInput
              value={activity.activityTitle}
              label={"Title"}
              onChangeText={(value: string) => {
                setActivity({ ...activity, activityTitle: value });
              }}
            />
            <TextInput
              value={activity.activityTime || ""}
              label={"Activity Time (in minutes)"}
              onChangeText={(value: string) => {
                setActivity({ ...activity, activityTime: value });
              }}
            />
            <TextInput
              value={activity.activityDescr}
              label={"Description"}
              onChangeText={(value: string) => {
                setActivity({ ...activity, activityDescr: value });
              }}
              multiline
            />

            <View style={Styles.flexRowCenterSpaceBetween}>
              <TextInput
                value={activity.activityAgeFrom}
                label={"From Months"}
                onChangeText={(value: string) => {
                  setActivity({
                    ...activity,
                    activityAgeFrom: value ? parseInt(value) : 0,
                  });
                }}
              />
              <TextInput
                value={activity.activityAgeTo}
                label={"To Months"}
                onChangeText={(value: string) => {
                  setActivity({
                    ...activity,
                    activityAgeTo: value ? parseInt(value) : 0,
                  });
                }}
              />
              <TextInput
                value={activity.activityRecommendedAge}
                label={"Recommended Age in months"}
                onChangeText={(value: string) => {
                  setActivity({
                    ...activity,
                    activityRecommendedAge: value ? parseInt(value) : 0,
                  });
                }}
              />
            </View>

            <MultiSelectInput
              label="Activity Skills"
              options={[
                ActivitySkills.LEFT_BRAIN,
                ActivitySkills.RIGHT_BRAIN,
                ActivitySkills.PHYSICAL,
                ActivitySkills.SENSORY,
                ActivitySkills.SOCIAL_EMOTION,
              ]}
              values={activity.activitySkills || []}
              onUpdate={(values: string[]) => {
                setActivity({ ...activity, activitySkills: values });
              }}
            />

            <TextInput
              value={activity?.tags?.toString()}
              label={"Tags"}
              onChangeText={(value: string) => {
                setActivity({ ...activity, tags: value.split(",") });
              }}
            />

            <View style={[Styles.rowCenter, { marginLeft: 20, marginTop: 10 }]}>
              <Text style={{ color: Colors.primary, fontSize: 16 }}>
                Is Premium Content
              </Text>
              <Checkbox
                uncheckedColor={Colors.grey}
                color={Colors.primary}
                status={activity.isPremium ? "checked" : "unchecked"}
                onPress={() => {
                  setActivity({
                    ...activity,
                    isPremium: !activity.isPremium,
                  });
                }}
              />
            </View>

            <TouchableOpacity
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: Colors.primary,
                borderRadius: 5,
                width: 150,
                alignSelf: "center",
                marginVertical: 20,
              }}
              onPress={saveChanges}
            >
              <Text style={{ fontSize: 20 }}>Save Changes</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }, [
    activity,
    handleFileSelect,
    isVideoUploading,
    pickImage,
    saveChanges,
    theme,
  ]);
  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);
      const q = query(collection(db, "activities"), orderBy("activityTitle"));
      const activities: Activity[] = [];

      const querySnapshot = await getDocs(q);
      for (const [index, doc] of querySnapshot.docs.entries()) {
        const _activitiy = doc.data() as Activity;
        _activitiy.key = doc.id;
        if (!_activitiy.activityImages[0]) {
          _activitiy.activityImages[0] = getDefaultThumbnail(index);
        }
        activities.push(_activitiy);
      }
      setActivityList(activities);
      setIsLoading(false);
    }

    fetchMyAPI();
  }, [db]);

  return (
    <Provider>
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0 },
        ]}
      >
        <View style={{ alignItems: "center", paddingHorizontal: 50 }}>
          {isLoading ? (
            <View style={{}}>
              <ActivityIndicator
                isLoading
                size="small"
                color={Colors[theme].primary}
              />
            </View>
          ) : activityList?.length ? (
            <View style={{ flexDirection: "row" }}>
              {renderList()}
              {renderDetails()}
            </View>
          ) : (
            <Text>No Activities Found</Text>
          )}
        </View>
      </ScrollView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
