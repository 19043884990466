/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import React, { useCallback, useEffect, useState } from "react";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { getAuth } from "firebase/auth";
import * as Clipboard from "expo-clipboard";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Badge, Checkbox, Modal, Portal, Provider } from "react-native-paper";

import moment from "moment";
import MultiSelectInput from "../components/MultiSelectInput";
import TextInput from "../components/TextInput";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import useColorScheme from "../hooks/useColorScheme";
import { Order, OrderItem, RootTabScreenProps, Vendor } from "../types";
import { _searchList, getDefaultThumbnail } from "../utils/helper";
import FontAwesomeButton from "../components/FontAwesomButton";

const S3_BUCKET = "monkitoxs3";
const REGION = "us-east-2";
const ACCESS_KEY = "AKIAZ2BTAGOCCLAMSDMD";
const SECRET_ACCESS_KEY = "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";
const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

//const analytics = getAnalytics(app);

export default function OrdersList({
  navigation,
}: RootTabScreenProps<"Discover">) {
  const theme = useColorScheme();

  const [isLoading, setIsLoading] = useState(true);
  const [userPhone, setUserPhone] = useState<string | undefined | null>();
  const [isUpdating, setIsUpdating] = useState(false);

  const [statusUpdateModalVisible, setStatusUpdateModalVisible] =
    useState(false);
  const [statisticsModal, setStatisticsModal] = useState(false);
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [filteredOrderList, setFilteredOrderList] = useState<Order[]>();
  const [order, setOrder] = useState<Order>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [products, setProducts] = useState<OrderItem[]>([]);
  const [searchText, setSearchText] = useState<string>();
  const [statusUpdate, setStatusUpdate] = useState<any>();

  const db = getFirestore();

  useEffect(() => {
    if (getAuth().currentUser) {
      setUserPhone(getAuth().currentUser?.phoneNumber);
    }
  }, []);

  useEffect(() => {
    if (searchText && searchText !== "") {
      setFilteredOrderList(_searchList(orderList, searchText));
    } else {
      setFilteredOrderList(undefined);
    }
  }, [orderList, searchText]);

  const listItem = useCallback(
    ({ item, index }: { item: Order; index: number }) => {
      return (
        <TouchableOpacity
          style={{
            marginLeft: 5,
            flexDirection: "row",
            marginTop: 5,
            width: Layout.window.width / 3,
          }}
          onPress={() => {
            setOrder(item);
            setSelectedIndex(index);
            setProducts(item.products.map((p) => ({ ...p, isChecked: false })));
          }}
        >
          <View style={{ padding: 5 }}>
            <View
              style={[Styles.flexRowCenterSpaceBetween, { paddingRight: 10 }]}
            >
              <Text style={[Styles.textTitle]}>{item.key}</Text>
              <Text style={[Styles.textTitle]}>{item.status}</Text>
            </View>
            <View
              style={[
                Styles.flexRowCenterSpaceBetween,
                { width: Layout.window.width / 3, paddingRight: 10 },
              ]}
            >
              <Text
                style={[Styles.textSubTitle]}
              >{`${item.products.length} items`}</Text>

              <Text style={[Styles.textSubTitle]}>
                {moment(item.placedAt.toDate()).format("DD-MMM-YYYY HH:mm")}
              </Text>
            </View>
            <View style={Styles.flexRow}>
              <Text style={[Styles.textTitle]}>{`${item.currency} `}</Text>'
              <Text style={[Styles.textTitle]}>{item.amount}</Text>'
            </View>
          </View>
        </TouchableOpacity>
      );
    },
    []
  );

  const renderList = useCallback(() => {
    return (
      <FlatList
        data={filteredOrderList || orderList}
        style={{
          marginTop: 10,
          paddingLeft: 50,
          height: Layout.window.height * 0.75,
          //marginBottom: 100 + ((playingNow.isPlayingNow || playingNow.Title || playingNow.live) ? 50 : 0)
        }}
        ListHeaderComponent={
          <View>
            <View style={Styles.flexRowCenterSpaceAround}>
              <TouchableOpacity onPress={() => setStatisticsModal(true)}>
                <Text style={{ fontSize: 20, color: Colors.primary }}>
                  {orderList.length} Orders
                </Text>
              </TouchableOpacity>
            </View>
            <TextInput
              value={searchText}
              label={"Search"}
              onChangeText={setSearchText}
            />
          </View>
        }
        keyExtractor={(item) => item.key || ""}
        renderItem={listItem}
      />
    );
  }, [filteredOrderList, listItem, orderList, searchText]);

  const renderDetails = useCallback(() => {
    return (
      <View
        style={{
          width: Layout.window.width / 2,
          borderWidth: 2,
          borderColor: Colors.primary,
          marginLeft: 10,
        }}
      >
        {!order && (
          <View style={styles.container}>
            <Text style={{ fontSize: 30 }}>
              Select an order to view the details
            </Text>
          </View>
        )}
        {order && (
          <View>
            <View style={[Styles.flexRow, { paddingHorizontal: 10 }]}>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 20, color: Colors.primary }}>
                  Order Details
                  <FontAwesomeButton
                    name="copy"
                    size={15}
                    onButtonPressed={() => {
                      let text = "";
                      const selectedProducts = products.filter(
                        (p) => p.isChecked
                      );
                      if (selectedProducts.length === 0) {
                        alert(
                          "Please select at least one product to copy the details"
                        );
                        return;
                      }
                      let address = `${order.address.name}, ${order.address.address}, ${order.address.area},${order.address.city}, ${order.address.state}, ${order.address.country}, ${order.address.postal}, ${order.address.phone}`;
                      selectedProducts.forEach((p) => {
                        text += `${order.key},${moment(
                          order.placedAt.toDate()
                        ).format("DD-MMM-YYYY HH:mm")},${p.sku},${p.name},${
                          p.quantity
                        },"${address}",${
                          ["Pending", "Confirmed"].includes(p.status)
                            ? "Pending"
                            : p.status
                        }\n`;
                      });
                      Clipboard.setStringAsync(text);
                      alert("Order details copied to clipboard");
                    }}
                  />
                </Text>
                <Text style={{ color: Colors.primary }}>
                  ID:{" "}
                  <Text>
                    {order.key}
                    <FontAwesomeButton
                      name="copy"
                      size={15}
                      onButtonPressed={() => {
                        Clipboard.setStringAsync(order.key || "");
                        alert("Order id copied to clipboard");
                      }}
                    />
                  </Text>
                </Text>
                <Text style={{ color: Colors.primary }}>
                  Status: <Text>{order.status}</Text>
                </Text>
                <Text style={{ color: Colors.primary }}>
                  Amount: <Text>{order.currency + " " + order.amount}</Text>
                </Text>
                <Text style={{ color: Colors.primary }}>
                  # of Items: <Text>{order.products.length}</Text>
                </Text>
                <Text style={{ color: Colors.primary }}>
                  Discount: <Text>{order.discount}</Text>
                </Text>
                <Text style={{ color: Colors.primary }}>
                  Shipping: <Text>{order.shipping}</Text>
                </Text>
                <Text style={{ color: Colors.primary }}>
                  Coupon: <Text>{order.coupon}</Text>
                </Text>
                <Text style={{ color: Colors.primary }}>
                  Payment Id: <Text>{order.paymentId}</Text>
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text style={{ fontSize: 20, color: Colors.primary }}>
                  Customer Details
                  <FontAwesomeButton
                    name="copy"
                    size={15}
                    onButtonPressed={() => {
                      Clipboard.setStringAsync(
                        `${order.address.name} \n${order.address.address}, ${order.address.area} \n${order.address.city}, ${order.address.state} \n${order.address.country}, ${order.address.postal} \n${order.address.phone}`
                      );
                      alert("Order ID copied to clipboard");
                    }}
                  />
                </Text>
                <Text style={{ color: Colors.primary }}>
                  {order.address.name}
                </Text>
                <Text>
                  {order.address.address}, {order.address.area}
                </Text>
                <Text>
                  {order.address.city}, {order.address.state}
                </Text>
                <Text>
                  {order.address.country}, {order.address.postal}
                </Text>
                <Text>{order.address.phone}</Text>
              </View>
            </View>

            <FlatList
              data={products}
              style={{
                marginTop: 10,
                paddingLeft: 50,
                height: Layout.window.height * 0.5,
              }}
              ListHeaderComponent={
                <View>
                  <Text style={{ fontSize: 20, color: Colors.primary }}>
                    Products
                  </Text>
                </View>
              }
              keyExtractor={(item) => item.key || ""}
              renderItem={({ item, index }) => (
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 5,
                    width: Layout.window.width / 2,
                  }}
                >
                  <Checkbox
                    uncheckedColor={Colors.grey}
                    color={Colors.primary}
                    status={item.isChecked ? "checked" : "unchecked"}
                    onPress={() => {
                      setProducts((currentValue) => {
                        const updatedProducts = currentValue;
                        updatedProducts[index].isChecked = !item.isChecked;
                        return [...updatedProducts];
                      });
                    }}
                  />
                  <Image
                    source={{ uri: item.image }}
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 10,
                    }}
                  />
                  <View style={{ padding: 5 }}>
                    <View
                      style={[
                        Styles.flexRowCenterSpaceBetween,
                        { paddingRight: 10 },
                      ]}
                    >
                      <Text style={[Styles.textTileTitle]}>{item.name}</Text>
                      <Badge>{item.status}</Badge>
                    </View>
                    <View
                      style={[
                        Styles.flexRowCenterSpaceBetween,
                        { width: Layout.window.width / 3, paddingRight: 10 },
                      ]}
                    ></View>
                    <View style={Styles.flexRowCenterSpaceBetween}>
                      <View style={Styles.flexRowCenter}>
                        <Text style={[Styles.textSubTitle]}>{`${
                          item.currency || "INR"
                        } `}</Text>
                        <Text style={[Styles.textSubTitle]}>{item.price}</Text>
                        <Text
                          style={[Styles.textColorMonkitox]}
                        >{` x ${item.quantity}`}</Text>
                      </View>
                      <Text style={Styles.textColorMonkitox}>
                        {
                          vendorList.find(
                            (vendor) => vendor.key === item.vendor
                          )?.Name
                        }
                      </Text>
                    </View>
                    <View style={Styles.flexRowCenterSpaceBetween}>
                      <Text>{item.sku}</Text>
                      <Text style={{ color: Colors.gold }}>
                        {item.customFields?.map((cf, index) => {
                          return `${(index > 0 ? "\n" : "") + cf.name} : ${
                            cf.value
                          }`;
                        })}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            />
            <View style={[Styles.flexRowCenter, { alignSelf: "center" }]}>
              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  backgroundColor: Colors.primary,
                  borderRadius: 5,
                  width: 150,
                  alignSelf: "center",
                  marginVertical: 20,
                }}
                onPress={() => {
                  if (products.filter((p) => p.isChecked).length === 0) {
                    alert(
                      "Please select at least one product to update the status"
                    );
                    return;
                  }
                  setStatusUpdateModalVisible(true);
                  const _product = products.filter((p) => p.isChecked)[0];
                  setStatusUpdate({
                    status: _product.status,
                    trackingId: _product.trackingId,
                  });
                  setIsUpdating(false);
                }}
              >
                <Text style={{ fontSize: 20 }}>Update Status</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  backgroundColor: Colors.primary,
                  borderRadius: 5,
                  width: 250,
                  alignSelf: "center",
                  marginVertical: 20,
                  marginLeft: 20,
                }}
                onPress={() => {
                  if (
                    products.filter((p) => p.status !== "Delivered").length > 0
                  ) {
                    alert(
                      "Can be completed only after all products are delivered"
                    );
                    return;
                  }
                  updateDoc(doc(db, "orders", order?.key || ""), {
                    status: "Delivered",
                    lastUpdatedBy: getAuth().currentUser?.uid,
                  }).then(() => {
                    setOrderList((currentValue) => {
                      if (selectedIndex) {
                        currentValue[selectedIndex].status = "Delivered";
                        return [...currentValue];
                      } else {
                        return currentValue;
                      }
                    });
                    alert("Order marked as completed");
                  });
                }}
              >
                <Text style={{ fontSize: 20, textAlign: "center" }}>
                  Mark order Complete
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    );
  }, [order, orderList, products]);
  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);
      const q = query(collection(db, "orders"), orderBy("placedAt", "desc"));
      const orders: Order[] = [];

      const querySnapshot = await getDocs(q);
      for (const [index, doc] of querySnapshot.docs.entries()) {
        const _order = doc.data() as Order;
        _order.key = doc.id;
        if (!_order.Thumbnail) {
          _order.Thumbnail = getDefaultThumbnail(index);
        }
        orders.push(_order);
      }
      const vendorData = query(collection(db, "Vendors"), orderBy("Name"));
      const vendors: any = [];
      const vendorQuery = await getDocs(vendorData);

      for (const [index, doc] of vendorQuery.docs.entries()) {
        const _vendor = doc.data() as any;
        _vendor.key = doc.id;
        if (!_vendor.Thumbnail) {
          _vendor.Thumbnail = getDefaultThumbnail(index);
        }
        vendors.push(_vendor);
      }
      setVendorList(vendors);
      setOrderList(orders);
      setIsLoading(false);
    }

    fetchMyAPI();
  }, [db]);

  return (
    <Provider>
      <Portal>
        <Modal
          visible={statusUpdateModalVisible}
          onDismiss={() => setStatusUpdateModalVisible(false)}
          contentContainerStyle={styles.modalContainer}
        >
          <View style={{ flex: 1, padding: 20 }}>
            <Text style={{ fontSize: 16, marginBottom: 20 }}>
              {"Update status of the selected products"}
            </Text>
            <MultiSelectInput
              label="Status"
              options={["Pending", "Confirmed", "Dispatched", "Delivered"]}
              values={[statusUpdate?.status]}
              singleSelect
              onUpdate={(values: string[]) => {
                setStatusUpdate((currentValue) => {
                  return { ...currentValue, status: values[0] };
                });
              }}
            />
            <TextInput
              value={statusUpdate?.trackingId}
              label={"Tracking Number"}
              onChangeText={(value: string) => {
                setStatusUpdate((currentValue) => {
                  return { ...currentValue, trackingId: value };
                });
              }}
            />
            {isUpdating ? (
              <ActivityIndicator
                isLoading
                size="large"
                color={Colors[theme].primary}
              />
            ) : (
              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  backgroundColor: Colors.primary,
                  borderRadius: 5,
                  alignSelf: "center",
                  marginVertical: 20,
                }}
                onPress={() => {
                  if (!statusUpdate?.status) {
                    alert("Please select a status to update");
                    return;
                  }
                  setIsUpdating(true);
                  const _products = products.map((p) => {
                    if (p.isChecked) {
                      return {
                        ...p,
                        status: statusUpdate?.status,
                        trackingId: statusUpdate?.trackingId || "",
                        ...(statusUpdate?.status === "Dispatched" && {
                          dispatchedAt: moment().toDate(),
                        }),
                        ...(statusUpdate?.status === "Delivered" && {
                          deliveredAt: moment().toDate(),
                        }),
                      };
                    }
                    return p;
                  });
                  console.warn(_products);
                  updateDoc(doc(db, "orders", order?.key || ""), {
                    products: _products,
                    lastUpdatedBy: getAuth().currentUser?.uid,
                  })
                    .then(() => {
                      getDoc(doc(db, "users", order?.userId || "")).then(
                        (doc) => {
                          const user = doc.data();
                          if (user && user.tokens && user.tokens.length) {
                            const functions = getFunctions();
                            // connectFunctionsEmulator(
                            //   functions,
                            //   "127.0.0.1",
                            //   5001
                            // );
                            const sendNotification = httpsCallable(
                              functions,
                              "sendNotification"
                            );
                            if (
                              statusUpdate?.status === "Dispatched" ||
                              statusUpdate?.status === "Delivered"
                            ) {
                              const updatedPrducts = products.filter(
                                (p) => p.isChecked
                              );
                              let pushMessage = updatedPrducts?.[0]?.name;
                              if (updatedPrducts.length > 1) {
                                pushMessage += ` and ${
                                  updatedPrducts.length - 1
                                } more products`;
                              }
                              pushMessage += ` has been ${statusUpdate?.status}`;
                              sendNotification({
                                payload: {
                                  message: {
                                    notification: {
                                      title: "Order Update",
                                      body: pushMessage,
                                    },
                                    token: user.tokens.at(-1),
                                    data: {
                                      action: "None", // ToDo : Change to Order after implementing in the App
                                      type: "Order",
                                      target: order?.key,
                                    },
                                    android: {
                                      notification: {
                                        // click_action: "TOP_STORY_ACTIVITY",
                                        body: pushMessage,
                                      },
                                    },
                                    apns: {
                                      payload: {
                                        aps: {
                                          category: "ORDER_UPDATE",
                                        },
                                      },
                                    },
                                  },
                                },
                              }).then((result) => {
                                // Read result of the Cloud Function.
                                /** @type {any} */
                                const data = result.data;
                                console.warn(result);
                                alert("Notification Sent");
                              });
                            }
                          }
                        }
                      );

                      setProducts(_products);
                      setIsUpdating(false);
                      setStatusUpdateModalVisible(false);
                    })
                    .catch((e) => {
                      setIsUpdating(false);
                      alert("Something went wrong");
                    });
                }}
              >
                <Text style={{ fontSize: 20 }}>{`Update ${
                  products.filter((p) => p.isChecked).length
                } Products`}</Text>
              </TouchableOpacity>
            )}
          </View>
        </Modal>
      </Portal>
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0, backgroundColor: Colors.white },
        ]}
      >
        <View style={{ alignItems: "center", paddingHorizontal: 50 }}>
          {isLoading ? (
            <View style={{}}>
              <ActivityIndicator
                isLoading
                size="small"
                color={Colors[theme].primary}
              />
            </View>
          ) : orderList?.length ? (
            <View style={{ flexDirection: "row" }}>
              {renderList()}
              {renderDetails()}
            </View>
          ) : (
            <Text>No Orders Found</Text>
          )}
        </View>
      </ScrollView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    backgroundColor: "black",
    pading: 25,
    width: Layout.window.width * 0.5,
    height: Layout.window.height * 0.7,
    alignSelf: "center",
    borderWidth: 5,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
});
